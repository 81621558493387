import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { getPost } from "../../middleware/wordpress_api";
import wordrpess_posts from "../../data/wordpress_post.json";
import { useParams } from "react-router";
import { WordpressItem } from "../../models/static_pages";

const StaticPages = () => {
  const [pageInfo, setPageInfo] = useState("");
  const [pageTitle, setPageTitle] = useState("");
  const { post_name } = useParams<{ post_name: string }>();
  useEffect(() => {
    const post_id = getPostId(post_name);
    if (post_id) {
      getPost(post_id.id).then((data) => {
        if (data) {
          setPageTitle(data.title.rendered);
          setPageInfo(data.content.rendered);
        }
      });
    }
  }, [post_name]);

  return (
    <Container>
      <div className="first-container container pt-5 static">
        <h1>{pageTitle}</h1>
        <div
          className="content"
          dangerouslySetInnerHTML={{
            __html: pageInfo,
          }}
        ></div>
      </div>
    </Container>
  );
};

function getPostId(post_name: string): WordpressItem | undefined {
  const get_post = wordrpess_posts as WordpressItem[];
  return get_post.find((item) => item.title === post_name);
}

export default StaticPages;
