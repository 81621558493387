import React from "react";
import { Row, Col } from "react-bootstrap";
import { Totals } from "../../../middleware/total";
import { SectionTypes } from "../../../models/checkout";
import { CartProductType } from "../../../models/product";
import CartProduct from "./product";

type CartCheckoutProps = {
  section: SectionTypes;
  totals: Totals;
  products: CartProductType[];
};

const CartCheckout: React.FC<CartCheckoutProps> = ({
  section,
  products,
  totals,
}) => (
  <div className="cart-checkout">
    <Row className="pb-3 pt-3 ">
      <Col>
        {" "}
        <h4>Productos en el Carrito</h4>
      </Col>
      <Col sm={12} className="products">
        {products
          ? products.map((product) => (
              <CartProduct
                key={`CartProduct_${product.id}`}
                product={product}
              />
            ))
          : "no products"}
      </Col>
      <Col sm={12} className="mt-4 total-text">
        <h6>Subtotal: ${totals.subtotal}</h6>
        {totals.tax ? <h6>IVA: ${totals.tax}</h6> : null}
        <h4>Total: ${totals.total}</h4>
      </Col>
    </Row>
  </div>
);

export default CartCheckout;
