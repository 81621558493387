import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import EmptyJumbotron from "../../components/empty-jumbotron";

import { fauth } from "../../middleware/firebase";
import { UserType } from "../../models/user_model";
import CartItems from "./cartItems";

const CartPage = () => {
  const [user] = useAuthState(fauth) as UserType[];

  if (!user || !user.uid) {
    return (
      <div className="container first-container">
        {" "}
        <EmptyJumbotron
          icon="far fa-user-circle"
          heading="Inicia sesión para ver tu carrito"
          sub_heading="Para poder comprar debes crear una cuenta"
          cta={{
            type: "link",
            txt: "Ingresa a tu cuenta",
            to: "/login",
            classes: "btn secondary",
          }}
        />
      </div>
    );
  }

  return (
    <div className="cart pb-5">
      <CartItems user={user} />
    </div>
  );
};

export default CartPage;
