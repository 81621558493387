import React from "react";
import Button from "../../components/buttons";
import { SectionTypes, section_breadcrumbs } from "../../models/checkout";

type CheckoutBreadcrumbsProps = {
  section: SectionTypes;
  sectionChange: any;
  back: any;
};

const CheckoutBreadcrumbs: React.FC<CheckoutBreadcrumbsProps> = ({
  section,
  sectionChange,
  back,
}) => {
  if (
    section === "thankyou" ||
    section === "order-received" ||
    section === "empty_cart"
  )
    return null;

  const b_lenght = section_breadcrumbs.length;
  const active_index = section_breadcrumbs.findIndex((b) => b.id === section);
  const active_section = section_breadcrumbs.find((b) => b.id === section);
  return (
    <div>
      <div>
        {" "}
        <Button
          onClick={back}
          children="Atras"
          icon="icon-keyboard_arrow_left"
          title="Go Back Button"
          buttonclasses="back mb-3"
        />
      </div>
      <div className="mb-2">
        <h2>{active_section?.title}</h2>
      </div>
      <div className="breadcrumb mb-4">
        {section_breadcrumbs.map((b, key) => (
          <button
            key={`CheckoutBreadcrumbs_${b.id}`}
            className={`${section === b.id ? "active" : ""}`}
            onClick={() => {
              return key < active_index ? sectionChange(b.id) : null;
            }}
          >
            {b.name}{" "}
            {key + 1 < b_lenght ? <span className="ml-2">{">"}</span> : null}
          </button>
        ))}
      </div>
    </div>
  );
};

export default CheckoutBreadcrumbs;
