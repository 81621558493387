import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const Footer: React.FC = () => (
  <div>
    <div>
      <Container>
        <Row>
          <Col>
            <div className="pt-5">
              ¿Necesitas ayuda con tu pedído?{" "}
              <Link to="/contacto">Ponte en contacto con nosotros.</Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
    <div className="footer p-3 ">
      <Container>
        <Row>
          <Col md={8} className="text-center text-md-left  pt-3 ">
            <ul>
              <li className="xxs">
                {" "}
                Ir a la página de inicio de <Link to="/">Micervesa.mx</Link> sin
                completar el la orden.
              </li>
            </ul>
          </Col>
          <Col md={4}></Col>
        </Row>
        <Row>
          <Col md={6}></Col>
          <Col md={6}>
            <div className="d-block d-lg-flex text-center my-3 align-items-center">
              <div className="mb-3  mb-md-0 mr-0 mr-lg-5 md-bold ">
                {" "}
                Visítanos en
              </div>
              <div>
                <a
                  href="https://www.twitter.com/MICerveSA"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <i className="white icon-twitter-square"></i>
                </a>
                <a
                  href="https://www.instagram.com/micervesa/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <i className="icon-instagram white"></i>
                </a>
                <a
                  href="https://www.linkedin.com/company/micervesa"
                  rel="noreferrer"
                >
                  {" "}
                  <i className="white icon-linkedin-square"></i>
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  </div>
);

export default Footer;
