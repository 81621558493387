import React from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import useProduct from "../../hooks/useProduct";
import ProductSlider from "./product-gallery";
import Button from "../../components/buttons";
import RelatedProducts from "./related";
import useProducts from "../../hooks/useProducts";

import EmptyJumbotron from "../../components/empty-jumbotron";
import AddToCartUI from "./addToCartUi";
import { UserType } from "../../models/user_model";
import Similar from "./similarSelect";
import SpecsTable from "./specsTable";
import { GrpsbiObj, Product } from "../../models/product";
import { formatNumber } from "../../middleware/common-functions";
import { NoStock } from "../../components/product-block";
import { renderIvaTxt } from "../../middleware/iva";

const ProductPage = () => {
  const { id } = useParams<{ id: string }>();
  const { result: product, loading } = useProduct(id);
  const user: UserType = JSON.parse(localStorage.getItem("user") || "{}");
  const { result: relatedProducts, loading: relatedProductsLoading } =
    useProducts(
      product
        ? ({
            grpsbi: product.grpsbi,
            limit: 6,
          } as any)
        : undefined,
      !Boolean(product)
    );

  const renderCartButton = (product: Product) => {
    if (product.price && product.price !== "NOPRICE" && product.stock) {
      return <AddToCartUI product={product} />;
    } else if (product.price && product.price !== "NOPRICE" && !product.stock) {
      return <NoStock />;
    }
    return null;
  };

  if (loading)
    return <Container className="first-container">Cargando...</Container>;
  if (!product)
    return (
      <Container className="first-container last-container">
        <EmptyJumbotron
          icon=""
          heading="Este producto no existe"
          sub_heading="Por favor, intenta con otro"
          text="(Mensaje Temporal Interno: este producto no tiene precio asignado por eso no se muestra)"
        />
      </Container>
    );
  const renderPrice = (product: Product, user: UserType) => {
    if (product.price && product.price !== "NOPRICE") {
      return (
        <div>
          {product.regular_price ? (
            <span className="regular_price"> ${product.regular_price}</span>
          ) : null}
          <span className="price-amount"> ${product.price}</span>{" "}
          <sup className="small">MXN</sup>
          <sup className="small text-secondary opacity_4 ml-2">
            {renderIvaTxt(product.iva)}
          </sup>
          <div>
            {product.contenido && product.price_num ? (
              <span className="badge badge-secondary p-1 pl-0 pr-2">
                ${formatNumber(product.price_num / product.contenido)}/
                {product.idunmd}
              </span>
            ) : null}
          </div>
        </div>
      );
    } else if (user && user.id) {
      return (
        <div className="">
          <span className="text-secondary">
            No tienes acceso a este producto
          </span>
        </div>
      );
    } else {
      return (
        <div className="">
          <Link to="/login">Inicia sesión</Link> para ver nuestros precios
        </div>
      );
    }
  };

  return (
    <Container className="first-container">
      <div className="single-product">
        {product.grpsbiObj ? (
          <BreadcrumbSection types={product.grpsbiObj} />
        ) : null}
        <Row className="pb-5">
          <Col md={5}>
            <ProductSlider product_id={product.id} images={product.images} />
          </Col>
          <Col md={7}>
            <div className="pl-0 pl-md-5">
              <h3 className="pt-4">{product.descp1}</h3>
              <div className="clave ">
                Marca:{" "}
                {product.brand ? (
                  <Link to={`/marca/${product.brand}`}>{product.brand}</Link>
                ) : (
                  "-"
                )}{" "}
              </div>
              <div className="clave "> Código: {product.idpart} </div>
              <div className="clave ">
                Disponibildad:{" "}
                <span className="stock-item">
                  {product.stock ? "En Stock" : "No Disponible"}
                </span>
              </div>
              <div className="product-description pt-2 pb-4">
                {product.short_description}
              </div>
              {user && user.id ? (
                <Button
                  // onClick={AddToCart}
                  children="Agregar a favoritos"
                  width="228px"
                  icon="icon-star-o mr-1"
                  title=""
                  buttonclasses="gray-bordered-light my-3"
                />
              ) : null}
              {product.similar?.length ? (
                <Similar products={product.similar} selected={product.id} />
              ) : null}

              {renderPrice(product, user)}
              {renderCartButton(product)}
            </div>
          </Col>
        </Row>
        <Row className="py-4">
          <Col sm={6}>
            {product.description ? (
              <h4 className="sub-title">Descripción</h4>
            ) : null}
          </Col>
          {/* <Col sm={6}>
            {" "}
            <Button
              // onClick={AddToCart}
              children="Descargar Hoja Técnica"
              width="100%"
              icon="icon-floppy-o "
              title=""
              buttonclasses="link"
            />
          </Col> */}
          <Col sm={10}>
            {product.description ? (
              <div className="full-description py-5">{product.description}</div>
            ) : null}
            {product.additionalData && product.additionalData.length >= 0 ? (
              <SpecsTable additionalData={product.additionalData} />
            ) : null}
          </Col>
        </Row>
      </div>
      {relatedProducts && relatedProducts.length ? (
        <RelatedProducts
          products={relatedProducts as any}
          loading={relatedProductsLoading}
        />
      ) : null}
    </Container>
  );
};

type BreadcrumbSectionProps = {
  types: GrpsbiObj;
};
const BreadcrumbSection: React.FC<BreadcrumbSectionProps> = ({ types }) => (
  <Breadcrumb placeholder="Cargando..." as="ul" className="p-0">
    <BreadcrumbItem>
      <Link to={`/productos/${encodeURIComponent(types[0])}/`}>{types[0]}</Link>
    </BreadcrumbItem>
    <BreadcrumbItem>
      <Link
        to={`/productos/${encodeURIComponent(types[0])}/${encodeURIComponent(
          types[1]
        )}`}
      >
        {types[1]}
      </Link>
    </BreadcrumbItem>
    <BreadcrumbItem active>
      <Link
        to={`/productos/${encodeURIComponent(types[0])}/${encodeURIComponent(
          types[1]
        )}/${encodeURIComponent(types[2])}`}
      >
        {types[2]}
      </Link>
    </BreadcrumbItem>
  </Breadcrumb>
);

export default ProductPage;
