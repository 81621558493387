import React from "react";
import { Link } from "react-router-dom";
import { AddressType } from "../../models/address";

type AddressProps = {
  address: AddressType;
  backUrl: string;
};
const Address: React.FC<AddressProps> = ({ address, backUrl }) => (
  <div className="card  material-shadows mb-3">
    <div className="preheader">
      <Link className="btn pl-0 text-capitalize " to={backUrl}>
        <i className="fas fa-chevron-left"></i> Atrás
      </Link>
    </div>
    <div className="card-body">
      <h5 className="card-title">{address.address_name}</h5>
      <p className="card-text">
        {address.address} {address.city} {address.state} {address.zip}
      </p>
      <p className="card-text">
        {address.name} {address.lastname} {address.phone}
      </p>
      <p className="card-text">{address.comments}</p>
    </div>
  </div>
);

export default Address;
