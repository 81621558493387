import React from "react";

import CartItem from "../../components/cart-item";
import { Row, Col } from "react-bootstrap";
import { Product } from "../../models/product";

type TableProps = {
  handleQtyChange: any;
  quantities: any;
  products: Product[];
  handleRemoveProduct: any;
};
const Table: React.FC<TableProps> = ({
  handleQtyChange,
  quantities,
  products,
  handleRemoveProduct,
}) => {
  return (
    <div className="cart-table bordered pb-3">
      <div className="d-none d-md-block bordered pb-4">
        <Row>
          <Col className="text-center">Productos</Col>
          <Col md={3} className="text-center">
            Precio Unitario
          </Col>
          <Col md={2} className="text-center">
            Cantidad
          </Col>
          <Col md={2} className="text-center">
            Total
          </Col>
          <Col md={2} className="text-center"></Col>
        </Row>
      </div>
      {products.map((item, i) => (
        <CartItem
          handleRemoveProduct={() => {
            handleRemoveProduct(item.id);
          }}
          product={item}
          key={item.id}
          qty={quantities[item.id as any]}
          handlePlus={() =>
            handleQtyChange("PLUS", item.id, quantities[item.id as any])
          }
          handleMinus={() =>
            handleQtyChange("MINUS", item.id, quantities[item.id as any])
          }
        />
      ))}
    </div>
  );
};
export default Table;
