import React from "react";
import PaymentMethods from "./payment-methods";
import PaymentForm from "./payment-form";
import Button from "../../../components/buttons";
import { PaymentTypes, SectionTypes } from "../../../models/checkout";

type CheckoutPageProps = {
  handleSections: (set_section: SectionTypes) => void;
  handleRadioChange: any;
  payment_type: PaymentTypes | false;
};
const PaymentPage = ({
  handleSections,
  handleRadioChange,
  payment_type,
}: CheckoutPageProps) => {
  return (
    <div className="payment-page">
      <PaymentMethods payment={payment_type} onChange={handleRadioChange} />
      <PaymentForm />

      <div className="mt-2">
        <Button
          onClick={() => {
            handleSections("resume");
          }}
          children="Continuar"
          width="100%"
          icon=""
          title="Submit"
          buttonclasses="dark"
        />
        <p className="xxs mt-3">* Continúe para revisar su orden</p>
      </div>
    </div>
  );
};

export default PaymentPage;
