import React from "react";
import Knowus from "./knowus";
import Brands from "./brands";
const WhoWeArePage = () => {
  return (
    <div className="who-we-are">
      <Knowus />
      <Brands />
    </div>
  );
};

export default WhoWeArePage;
