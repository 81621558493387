import React, { useEffect } from "react";
import firebase from "firebase/app";
import { db } from "../../../middleware/firebase";
import Input, { Select } from "../../../components/input";
import { Row, Col } from "react-bootstrap";
import Button from "../../../components/buttons";
import { SectionTypes } from "../../../models/checkout";
import { AddressType } from "../../../models/address";
import states from "../../../data/states.json";
import { getAddresses } from "../../../middleware/user-functions";

type CheckoutPageProps = {
  handleSections: (set_section: SectionTypes) => void;
  setSelectedAddress: (set_section: AddressType | false) => void;
  handleType: any;
  user: firebase.User | null | undefined;
  deliveryData?: AddressType;
};

const AddressPage = ({
  handleSections,
  handleType,
  setSelectedAddress,
  deliveryData,
  user,
}: CheckoutPageProps) => {
  const [prevAddress, setPrevAddress] = React.useState<AddressType[] | false>(
    false
  );
  const [saveAddress, setSaveAddress] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  useEffect(() => {
    getAddressesFun();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSaveAddress = (ev: { target: HTMLInputElement }) => {
    const value = ev.target.checked;
    setSaveAddress(value);
  };
  const handleSelectAddress = (ev: { target: HTMLSelectElement }) => {
    const value = ev.target.value;
    if (prevAddress === false) {
      return;
    }
    if (value === "false") {
      setSelectedAddress(false);
      return;
    }
    ///get select address
    const selectedAddress = prevAddress?.find(
      (address) => address.id === value
    ) as AddressType;
    if (!selectedAddress) {
      return;
    }
    setSelectedAddress(selectedAddress);
  };

  const handleNext = () => {
    ///if saveAddress save address to firebase
    if (user && saveAddress && deliveryData) {
      setLoading(true);
      db.collection("addresses")
        .doc(user.uid)
        .collection("address")
        .add(deliveryData)
        .then(() => {
          handleSections("payment");
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    } else {
      handleSections("payment");
    }
  };

  const getAddressesFun = async () => {
    if (user) {
      const addresses = getAddresses(user);
      if (addresses) {
        setPrevAddress(addresses);
      }
    }
  };

  return (
    <div className="address-page">
      <Row>
        {prevAddress && prevAddress.length ? (
          <Col md={12}>
            <Select
              id="state"
              label="Direcciones Guardadas"
              placeholder="Selecciona una dirección"
              value={deliveryData?.id}
              onChange={handleSelectAddress}
              options={prevAddress.map((address) => ({
                value: address.id!,
                label: `${address.address_name} | ${address.address} ${address.city}`,
              }))}
            />

            <hr />
          </Col>
        ) : null}
        {deliveryData?.id ? null : (
          <AddressForm
            handleType={handleType}
            deliveryData={deliveryData}
            saveAddress={saveAddress}
            handleSaveAddress={handleSaveAddress}
          />
        )}

        <Col md={12}>
          <Button
            onClick={handleNext}
            children="Continuar"
            disabled={loading}
            width="100%"
            icon=""
            buttonclasses="dark mt-2"
            title="Submit"
          />
        </Col>
      </Row>
    </div>
  );
};

type AddressFormProps = {
  handleType: any;
  deliveryData?: AddressType;
  saveAddress?: boolean;
  showAditionalData?: boolean;
  handleSaveAddress?: any;
};

export const AddressForm: React.FC<AddressFormProps> = ({
  handleType,
  deliveryData,
  saveAddress,
  handleSaveAddress,
  showAditionalData = true,
}) => (
  <Row>
    <div className="col-md-12">
      <span>Agrega una nueva dirección:</span>
    </div>

    {showAditionalData ? (
      <Input
        id="name"
        className="col-md-6"
        label="Nombre"
        type="text"
        value={deliveryData?.name}
        onChange={handleType}
      />
    ) : null}
    {showAditionalData ? (
      <Input
        id="lastname"
        className="col-md-6"
        label="Apellidos"
        type="text"
        value={deliveryData?.lastname}
        onChange={handleType}
      />
    ) : null}
    {showAditionalData ? (
      <Input
        id="phone"
        className="col-md-12"
        label="Teléfono"
        type="phone"
        value={deliveryData?.phone}
        onChange={handleType}
      />
    ) : null}
    <Input
      id="address"
      className="col-md-8"
      label="Dirección"
      type="text"
      value={deliveryData?.address}
      onChange={handleType}
    />
    <Input
      id="zip"
      className="col-md-4"
      label="CP"
      type="text"
      value={deliveryData?.zip}
      onChange={handleType}
    />
    <Input
      id="city"
      className="col-md-6"
      label="Ciudad"
      type="text"
      value={deliveryData?.city}
      onChange={handleType}
    />

    <Select
      id="state"
      className="col-md-6"
      label="Estado"
      placeholder="Selecciona tu estado"
      value={deliveryData?.state}
      onChange={handleType}
      options={states.map((item) => ({
        value: item.name,
        label: item.name,
      }))}
    />

    {showAditionalData ? (
      <Input
        id="comments"
        help="Ayúdanos a encontrar tu dirección"
        className="col-md-12"
        rows={2}
        label="Comentarios"
        type="text"
        value={deliveryData?.comments}
        onChange={handleType}
      />
    ) : null}
    {saveAddress ? (
      <Input
        id="address_name"
        className="col-md-6"
        placeholder="Ej: Sucursal Principal"
        label="Nombre de la Dirección"
        type="text"
        value={deliveryData?.address_name}
        onChange={handleType}
      />
    ) : (
      <div className="col-md-6"> </div>
    )}
    {handleSaveAddress ? (
      <div className="col-md-6">
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            onChange={handleSaveAddress}
            id="saveAddress"
            checked={saveAddress}
          />
          <label className="custom-control-label" htmlFor="saveAddress">
            Guardar esta dirección
          </label>
        </div>
      </div>
    ) : null}
  </Row>
);

export default AddressPage;
