import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Button from "../../components/buttons";
const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 2,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
        dots: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
      },
    },
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ],
};

const cat_data = [
  {
    image: "/images/home/product-1-c.jpg",
    name: "Fermentis - SafBrew DA16 (500 grs)",
    description: "SafBrew DA16 es una solución poderosa…",
    clave: "Clave: LC22 ",
    price: "MXN $2,481.40 ",
  },
  {
    image: "/images/home/product-2-c.jpg",
    name: "Tabletas Campden (5 paquetes con 50 pzas cada uno)",
    description: "SafBrew DA16 es una solución poderosa…",
    clave: "Clave: HB025 ",
    price: "MXN $401.65 ",
  },
  {
    image: "/images/home/product-4-c.jpg",
    name: "Fermentis - SafSpirit M-1 (Malt) (500 grs)",
    description: "SafBrew DA16 es una solución poderosa…",
    clave: "Clave: LCS01 ",
    price: "MXN $927.23",
  },
  {
    image: "/images/home/product-5-c.jpg",
    name: "Lúpulo US Sabro (500 grs)",
    description: "SafBrew DA16 es una solución poderosa…",
    clave: "Clave: LC22 ",
    price: "MXN $2,481.40 ",
  },
  {
    image: "/images/home/product-5-c.jpg",
    name: "Lúpulo US Sabro (500 grs)",
    description: "SafBrew DA16 es una solución poderosa…",
    clave: "Clave: LC22 ",
    price: "MXN $2,481.40 ",
  },
];
const FeaturedProducts = () => {
  return (
    <section className="featured-products">
      <Container className="pt-5 pb-5">
        <Row className="mb-3 text-left py-3">
          <Col>
            <h3>Nuevos Productos</h3>
          </Col>
        </Row>
        <Slider {...settings} className="p-3">
          {cat_data.map((item, ind) => (
            <Featured
              image={item.image}
              name={item.name}
              clave={item.clave}
              price={item.price}
            />
          ))}
        </Slider>
      </Container>
    </section>
  );
};

export default FeaturedProducts;

const Featured = ({
  ind,
  image,
  name,
  clave,
  price,
  handleClick,
}: {
  [key: string]: any;
}) => (
  <div key={ind} className="featured-product px-2">
    <div className="pb-3">
      <img src={image} alt="Mi cerveza" />
    </div>
    <h5>{name}</h5>
    <div className="clave">{clave}</div>
    <div className="price">{price}</div>
    <div>
      <Button
        onClick={handleClick}
        children="Agregar"
        width="60%"
        icon=""
        title=""
        buttonclasses="rounded mt-2"
      />
    </div>
  </div>
);
