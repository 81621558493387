import React from "react";
import { ProductAditionalData } from "../../models/product";

type SpecsTableProps = {
  additionalData: ProductAditionalData[] | undefined;
};
const SpecsTable: React.FC<SpecsTableProps> = ({ additionalData }) => (
  <div className="specs_table">
    {additionalData?.length ? (
      <>
        <h4 className="sub-title">Especificaciones</h4>
        {additionalData.map((item) => (
          <SpecsTableContainer
            additionalData={item}
            key={`SpecsTableContainer${item.iddadd}`}
          />
        ))}
      </>
    ) : null}
  </div>
);

type SpecsTableContainerProps = {
  additionalData: ProductAditionalData;
};
const SpecsTableContainer: React.FC<SpecsTableContainerProps> = ({
  additionalData,
}) => {
  if (!additionalData.xipcas) {
    return null;
  }
  return (
    <table className="table table-striped">
      <tbody>
        {additionalData.xipcas
          .filter((item) => item.value)
          .map((item) => (
            <tr key={`SpecsTable_${item.idcmpo}`}>
              <td>{item.title || item.idcmpo}</td>
              <td>{item.value}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default SpecsTable;
