import { useEffect } from "react";
import {
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore";
import { toast } from "react-toastify";
import { db } from "../middleware/firebase";
import useUserAuth from "./useUserAuth";

export default function useUserCollection<T = any>(config: {
  collection: string;
  subcollection?: string;
  itemId?: string;
  showNotification?: boolean;
}) {
  const {
    collection,
    itemId,
    showNotification = false,
    subcollection,
  } = config;
  const { user, loading: userLoading } = useUserAuth();
  const [cllxn, cllxnLoading, cllxnError] = useCollectionData(
    collection && !itemId && user && user.id
      ? db
          .collection(collection)
          .doc(user.id)
          .collection(subcollection || collection)
      : undefined,
    {}
  );
  const [doc, docLoading, docError] = useDocumentData(
    collection && itemId && user && user.id
      ? db
          .collection(collection)
          .doc(user.id)
          .collection(subcollection || collection)
          .doc(itemId)
      : undefined,
    {}
  );
  useEffect(() => {
    if (showNotification && (docError || cllxnError)) {
      toast.error("Ocurrió un error en esta búsqueda");
    }
  }, [showNotification, docError, cllxnError]);
  return {
    loading: userLoading || cllxnLoading || docLoading,
    error: cllxnError || docError,
    data: (itemId ? doc : cllxn) as unknown as T,
  };
}
