import { useState } from "react";
import { Modal, Container } from "react-bootstrap";
import Button from "../buttons";
type SaveOrderModalProps = {
  show: boolean;
  loading: boolean;
  setShow: (newState: boolean) => void;
  saveOrder: (orderName: string) => void;
};
export const SaveOrderModal: React.FC<SaveOrderModalProps> = ({
  show,
  setShow,
  saveOrder,
  loading,
}) => {
  const [orderName, setOrderName] = useState("");
  return (
    <>
      <Modal show={show} animation={false}>
        <Modal.Header>
          <div className="md">
            {" "}
            <i className="mr-2"></i>Guardar Mi Pedido
          </div>
          <Button
            onClick={() => setShow(false)}
            children=""
            width="10px"
            icon="fas fa-times"
            title=""
            buttonclasses="mini-cart"
          />
        </Modal.Header>

        <Modal.Body>
          <Container>
            <h4>¿No estás listo para ordenar?</h4>
            <p>Guarda tu orden para después </p>
            <div className="form-group">
              <input
                type="text"
                className="form-control text-center"
                placeholder="Receta para IPA"
                onChange={(e) => setOrderName(e.target.value)}
                value={orderName}
              />
              <small className="form-text text-muted">
                Este nombre sirve para que puedas identificar tu orden más
                fácilmente
              </small>
            </div>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <div className="text-center mt-4 mb-2">
              <Button
                onClick={() => saveOrder(orderName)}
                children="Guardar"
                width="60%"
                disabled={loading}
                icon=""
                title=""
                buttonclasses="primary"
              />
            </div>
          </Container>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SaveOrderModal;
