import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

import Account from "./account";
import Orders from "./orders";
import Quotes from "./quotes";
import Quote from "./quote";
import Addresses from "./addresses";

const nav = [
  { name: "Cuenta", link: "cuenta", icon: "far fa-user" },
  { name: "Direcciones", link: "direcciones", icon: "fas fa-map-marked-alt" },
  { name: "Órdenes", link: "pedidos", icon: "fas fa-box-open" },
  { name: "Cotizaciones", link: "cotizaciones", icon: "fas fa-boxes" },
];

const AccountPage = () => {
  const { section = "cuenta", item_id } = useParams<{
    section: string;
    item_id: string;
  }>();
  const [sectionTitle, setSectionTitle] = useState("Mi Cuenta");
  const [sectionRender, setSectionRender] = useState(<Account />);
  useEffect(() => {
    rederSection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [section]);
  const rederSection = () => {
    switch (section) {
      case "pedidos":
        setSectionTitle("Órdenes");
        setSectionRender(<Orders />);
        break;
      case "cotizaciones":
        setSectionTitle("Cotizaciones Guardadas");
        setSectionRender(<Quotes />);
        break;
      case "cotizacion":
        setSectionTitle("Cotizaciones Guardadas");
        setSectionRender(<Quote order_id={item_id} />);
        break;
      case "direcciones":
        setSectionTitle("Direcciones");
        setSectionRender(<Addresses />);
        break;
      default:
        setSectionTitle("Mi Cuenta");
        setSectionRender(<Account />);
        break;
    }
  };

  return (
    <div className="first-container last-container bg_gray min-vh-80 account_page">
      <div className="container">
        <h2 className=" mt-4">{sectionTitle}</h2>
        <div className="row justify-content-between mt-4">
          <div className="col-md-3 mb-3">
            <div className="list-group  material-shadows ">
              {nav.map((item, key) => (
                <Link
                  key={`my_account_${key}`}
                  to={`/cuenta/${item.link}`}
                  className={`list-group-item list-group-item-action ${
                    item.link === section && "active"
                  }`}
                >
                  <i className={` mr-2 fa-fw ${item.icon}`}></i>
                  {item.name}
                </Link>
              ))}
            </div>
          </div>
          <div className="col-md-9">{sectionRender}</div>
        </div>
      </div>
    </div>
  );
};

export default AccountPage;
