import firebase from "firebase/app";
import { db } from "./firebase";
import { AddressType } from "../models/address";

export const getAddresses = (user: firebase.User | null | undefined) => {
  if (user) {
    try {
      db.collection("addresses")
        .doc(user.uid)
        .collection("address")
        .get()
        .then((querySnapshot) => {
          const addresses: AddressType[] = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data() as AddressType;
            addresses.push({ ...data, id: doc.id });
          });
          return addresses;
        });
    } catch (error) {
      console.log("user-functions.ts:22 | error", error);
      return false;
    }
  }
};
