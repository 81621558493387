import { useAsync } from "react-async-hook";
import { db } from "../middleware/firebase";

export type ProductTag = {
  productTagId: string;
  primaryType: string;
  secondaryType: string;
  tertiaryType: string;
  count: Number;
  products: string[];
};

export async function getProductTags() {
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const productTagsRef = db.collection("product-tags");
  let menuItems = productTagsRef.orderBy("primaryType");
  if (user.priceListId) {
    menuItems = productTagsRef.where(
      "priceLists",
      "array-contains",
      user.priceListId
    );
  }
  const productTags: ProductTag[] = (await menuItems.get()).docs.map((doc) =>
    doc.data()
  ) as any;
  const tagsDictionary: {
    [PrimaryTag: string]: { [SecondaryTag: string]: string[] };
  } = {};
  productTags.forEach(({ primaryType, secondaryType, tertiaryType }) => {
    if (!tagsDictionary[primaryType]) tagsDictionary[primaryType] = {};
    const tertiaryTags = tagsDictionary[primaryType][secondaryType] || [];
    tagsDictionary[primaryType][secondaryType] = [
      ...tertiaryTags,
      tertiaryType,
    ];
  });
  delete tagsDictionary[""];
  return tagsDictionary;
}

export default function useProductTags() {
  return useAsync(getProductTags, []);
}
