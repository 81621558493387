import React, { useState, useEffect, useMemo } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 3,
};
const settings2 = {
  dots: false,
  arrows: false,
};

type ProductSliderProps = {
  images: string[];
  product_id: string;
};
const ProductSlider: React.FC<ProductSliderProps> = ({
  images,
  product_id,
}) => {
  const [nav1, setNav1]: any = useState(null);
  const [nav2, setNav2]: any = useState(null);

  let slider1: any = useMemo(() => {}, []);
  let slider2: any = useMemo(() => {}, []);
  if (images.length === 0) {
    images.push("https://via.placeholder.com/500");
  }
  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  }, [slider1, slider2]);

  return (
    <div className="product-gallery">
      <Slider
        {...settings}
        asNavFor={nav2}
        ref={(slider) => (slider1 = slider)}
      >
        {images.map((image, ind) => (
          <div key={`${product_id}_${ind}`}>
            <img src={image} alt="product" />
          </div>
        ))}
      </Slider>
      {images.length > 1 ? (
        <div className="gallery-thumbnails">
          <Slider
            {...settings2}
            asNavFor={nav1}
            ref={(slider) => (slider2 = slider)}
            slidesToShow={3}
            swipeToSlide={true}
            focusOnSelect={true}
          >
            {images.map((image, ind) => (
              <div key={`${product_id}_${ind}_tuhmbs`}>
                <img src={image} alt="product" />
              </div>
            ))}
          </Slider>
        </div>
      ) : null}
    </div>
  );
};

export default ProductSlider;
