import React from "react";
import ShippingPage from "./shipping";

import { DeliveryTypes } from "../../models/checkout";
type CheckoutPageProps = {
  next: () => void;
  handleDelivery: any;
  delivery_type: DeliveryTypes | false;
};

const DeliverySection: React.FC<CheckoutPageProps> = ({
  next,
  delivery_type,
  handleDelivery,
}) => {
  return (
    <ShippingPage
      next={next}
      delivery_type={delivery_type}
      handleDelivery={handleDelivery}
    />
  );
};

export default DeliverySection;
