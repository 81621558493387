import React from "react";
import { Col, Row } from "react-bootstrap";
import Button from "../../../components/buttons";
import { OrderType } from "../../../models/checkout";
import { paymentTxt } from "../../../middleware/common-functions";

type OrderInfoProps = {
  order: OrderType;
};

const OrderInfo: React.FC<OrderInfoProps> = ({ order }) => {
  const handlePrint = () => {};
  return (
    <div className="order-info pt-2 pb-3">
      <Row>
        <Col md={12}>
          <div className="md-semi">Orden de Compra realizada con éxito</div>
          <div className="sm">
            Forma de Pago: {paymentTxt(order.payment_type)}{" "}
          </div>

          <div>
            <p>
              En este momento estamos procesando su pedido, nuestro equipo se
              pondrá en contacto con ustedes una vez esté autorizado
            </p>
          </div>
        </Col>
      </Row>
      <div className="py-3 d-block d-md-flex">
        <div>
          <Button
            onClick={handlePrint}
            children="Imprimir"
            width="180px"
            icon=""
            title=""
            buttonclasses="gray mr-0 mr-md-5 mb-3 mb-md-3"
          />
        </div>
        <div>
          <Button
            onClick={handlePrint}
            children="Seguir Comprando"
            width="180px"
            icon=""
            title=""
            buttonclasses="gray-bordered"
          />
        </div>
      </div>
    </div>
  );
};

export default OrderInfo;
