import { isMobile } from "react-device-detect";
import { useHistory } from "react-router";
import moment from "../../middleware/moment";
import EmptyJumbotron from "../../components/empty-jumbotron";
import Loader from "../../components/utils/loader";
import useUserCollection from "../../hooks/useUserCollection";

const Orders = (props: any) => {
  const { data: orders, loading } = useUserCollection<{ [key: string]: any }[]>(
    {
      collection: "quotes",
      showNotification: true,
    }
  );
  const history = useHistory();
  const selectOrder = (ev: any) => {
    const order_id = ev.currentTarget.dataset.order_id;
    history.push(`/cuenta/cotizacion/${order_id}`);
  };

  return (
    <div className="my_orders">
      <div className="mb-3 card material-shadows">
        <div className="card-body">
          {loading ? (
            <Loader />
          ) : orders ? (
            <OrdersTable
              data={orders.reduce((acc, order) => {
                let { id } = order;
                return { ...acc, [id]: order };
              }, {})}
              clickFun={selectOrder}
            />
          ) : (
            <EmptyJumbotron
              icon="fas fa-clipboard-list"
              heading="Sin Cotizaciones Guardadas"
              text="Guarda tus cotizaciones desde el carrito de compra"
            />
          )}
        </div>
      </div>
    </div>
  );
};

const OrdersTable = ({ data, clickFun }: { data: any; clickFun: any }) => {
  console.log(data);
  if (isMobile) {
    return (
      <div className="list-group">
        {Object.keys(data).map((key, index) => {
          const item = data[key];
          return (
            <button
              onClick={clickFun}
              className="list-group-item list-group-item-action "
              key={key}
              data-order_id={key}
            >
              <div className="d-flex w-100 justify-content-between">
                <h5 className="mb-1">${item.total.total}</h5>
                <small>
                  {moment(item.timestamp.toDate()).format("MMM DD ")}
                </small>
              </div>
              <p className="mb-1">{item.name}</p>
            </button>
          );
        })}
      </div>
    );
  }
  return (
    <table className="table table-striped table-hover hover">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Nombre</th>
          <th scope="col">Total</th>
          <th scope="col">Fecha</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(data).map((key, index) => {
          const item = data[key];
          return (
            <tr key={key} onClick={clickFun} data-order_id={key}>
              <th scope="row">{index + 1}</th>
              <td>{item.name}</td>
              <td>${item.total.total}</td>
              <td>{moment(item.timestamp.toDate()).format("MMM DD ")}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Orders;

// export default function Order() {
//   return <div>Quotes</div>;
// }
