import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
const LinksData = [
  {
    name: "Catálogo",
    items: [
      {
        name: "Nuevos Productos",
        link: "",
      },
      {
        name: "Maltas",
        link: "/productos/Maltas",
      },
      {
        name: "Lúpulos",
        link: "/productos/Lúpulos",
      },
      {
        name: "Levaduras",
        link: "/productos/Levaduras",
      },
    ],
  },

  {
    name: "Ayuda",
    items: [
      {
        name: "¿Cómo ser cliente?",
        link: "/como-ser-cliente",
      },
      {
        name: "Ayuda con tu orden",
        link: "/info/ayudapedido",
      },
      {
        name: "Rastreo de pedidos",
        link: "/info/rastreo",
      },
      {
        name: "Formas de pago",
        link: "/info/formaspago",
      },
      {
        name: "Políticas generales",
        link: "/info/políticasgenerales",
      },
      {
        name: "Facturación",
        link: "/info/facturacion",
      },
    ],
  },
  {
    name: "Nosotros",
    items: [
      {
        name: "Quienes somos",
        link: "/quienes-somos",
      },
      {
        name: "Misión",
        link: "/info/mision",
      },
      {
        name: "Visión",
        link: "/info/vision",
      },
    ],
  },
];

const Footer: React.FC = () => (
  <div className="footer p-3 p-md-5 ">
    <div className="logo__footer">
      <img src="/images/footer/logo-footer.png" alt="Mi cerveza" />
    </div>
    <Container>
      <Row>
        <Col
          md={4}
          className="text-center text-md-left  pt-5 pt-md-0  mt-5 mt-md-0"
        >
          <ul>
            <li className="md-bold"> Horario</li>
            <li className="xs"> Lunes a viernes 9 am – 6 pm</li>
            <li className="xs">(Hora de Chihuahua)</li>
          </ul>
        </Col>
        <Col md={4}></Col>
        <Col md={4} className="text-center text-md-left"></Col>
      </Row>
      <Row>
        {LinksData.map((link, idx) => {
          return (
            <Col
              className="text-left col-6 col-md-4 col-lg-3 pt-4 pt-md-3"
              key={idx}
            >
              <div>
                {link.name && <h3 className="mb-4 white">{link.name}</h3>}
                <ul>
                  {link.items.map((item, idx) => {
                    return (
                      <li className="mb-3" key={idx}>
                        <Link to={item.link}>{item.name}</Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </Col>
          );
        })}
        <Col className="text-center text-lg-left col-12 col-lg-3 pt-4 pt-md-3">
          <div className="contact-footer">
            <h3 className="mb-4 white">Contáctanos</h3>
            <ul>
              <li className="sm-bold mb-2">Baja California:</li>
              <li>
                <span className="whatsapp">
                  {" "}
                  <img src="/images/footer/whatsapp.png" alt="Mi Cerveza" />
                </span>{" "}
                <a href="tel:664 168 49 286">
                  {" "}
                  <span> +52 (664) 168 49 286</span>
                </a>
              </li>
              <a href="mailto:ventas.tijuana@micervesa.mx">
                {" "}
                <li className="xs-bold">ventas.tijuana@micervesa.mx</li>
              </a>
            </ul>
            <ul>
              <li className="sm-bold mb-2">Resto del país:</li>
              <li>
                <span className="whatsapp">
                  {" "}
                  <img src="/images/footer/whatsapp.png" alt="Mi Cerveza" />
                </span>{" "}
                <a href="tel:614 427 86 22">
                  {" "}
                  <span> +52 (614) 427 86 22</span>
                </a>
              </li>
              <li>
                <i className="icon-phone-square white"></i>

                <a href="tel:614 413 02 16">
                  {" "}
                  <span>+52 (614) 413 02 16</span>
                </a>
              </li>
              <a href="mailto:ventas@micervesa.mx">
                {" "}
                <li className="xs-bold">ventas@micervesa.mx</li>
              </a>
            </ul>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          {" "}
          <div className="payment mt-4 text-center text-md-left">
            <Row>
              <Col sm={12}>
                <div className="mb-2 d-block d-md-none  mb-md-0 md-bold ">
                  {" "}
                  Aceptamos
                </div>
              </Col>
              <Col>
                {" "}
                <span className="payment-logo">
                  {" "}
                  <img src="/images/footer/payment-1.png" alt="Mi Cerveza" />
                </span>
              </Col>
              <Col>
                {" "}
                <span className="payment-logo">
                  {" "}
                  <img src="/images/footer/payment-2.png" alt="Mi Cerveza" />
                </span>
              </Col>
              <Col>
                {" "}
                <span className="payment-logo">
                  {" "}
                  <img src="/images/footer/payment-3.png" alt="Mi Cerveza" />
                </span>
              </Col>
            </Row>
          </div>
        </Col>
        <Col md={6}>
          <div className="d-block d-lg-flex text-center mt-4 align-items-center">
            <div className="mb-2  mb-md-0 mr-0 mr-lg-5 md-bold ">
              {" "}
              Visítanos en
            </div>
            <div>
              <Link
                to={{
                  pathname: "https://twitter.com/MICerveSA",
                }}
                target="_blank"
              >
                {" "}
                <i className="white icon-twitter-square"></i>
              </Link>
              <Link
                to={{ pathname: "https://www.instagram.com/micervesa/" }}
                target="_blank"
              >
                {" "}
                <i className="icon-instagram white"></i>
              </Link>
              <Link
                to={{
                  pathname: "https://www.linkedin.com/company/micervesa/about/",
                }}
                target="_blank"
              >
                {" "}
                <i className="white icon-linkedin-square"></i>
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);

export default Footer;
