import React from "react";
import ShippingItem from "../../../components/shipping-option";
import { Form, Row, Col } from "react-bootstrap";
import Button from "../../../components/buttons";
import { DeliveryTypes, SectionTypes } from "../../../models/checkout";

const delivery_options = [
  {
    id: "delivery_charge",
    name: "shipping_charge",
    icon: "fas fa-hand-holding-usd",
    label: "Envío por COBRAR DESTINO",
    description: "Pagó a la entrega del envío",
  },
  // {
  //   id: "delivery",
  //   name: "shipping",
  //   icon: "fas fa-plane-departure",
  //   label: "Envío a Domicilio",
  //   description: "MAX 10 Kg todo México",
  // },
  {
    id: "plane",
    name: "shipping",
    icon: "icon-plane",
    label: "Paquetería Aéreo",
    description: "MAX 10 Kg todo México",
  },
  {
    id: "land",
    name: "shipping",
    icon: "icon-truck",
    label: "Paquetería Terrestre",
    description: "MAX 1000 kg todo México",
  },
  {
    id: "pallet",
    name: "shipping",
    icon: "icon-archive",
    label: "Tarimas LTL",
    description: "Envío a todo México",
  },
  {
    id: "pickup",
    name: "shipping",
    icon: "fas fa-walking",
    label: "Yo lo recojo",
    description: "En tu sucursal más cercana ",
  },
];
type CheckoutPageProps = {
  next: (section?: SectionTypes) => void;
  handleDelivery: () => void;
  delivery_type: DeliveryTypes | false;
};
const ShippingPage = ({
  next,
  delivery_type,
  handleDelivery,
}: CheckoutPageProps) => {
  const handleComplete = () => {
    if (delivery_type) {
      switch (delivery_type) {
        case "pickup":
          next("branch-selection");
          break;
        ////add more cases here when needed

        default:
          next();
          break;
      }
    }
  };

  return (
    <div className="shipping">
      <Row>
        <div className="mt-2 w-100">
          <Form>
            {delivery_options.map((item, indx) => (
              <ShippingItem
                id={item.id}
                key={`ShippingItem_${item.id}`}
                active={delivery_type === item.id}
                name={item.name}
                icon={item.icon}
                label={item.label}
                description={item.description}
                clickFun={handleDelivery}
              />
            ))}
            <Col md={12}>
              <Button
                onClick={handleComplete}
                children="Continuar"
                width="80%"
                icon=""
                title=""
                buttonclasses="dark mt-2"
              />
            </Col>
          </Form>
        </div>
      </Row>
    </div>
  );
};

export default ShippingPage;
