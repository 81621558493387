import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const cat_data = [
  {
    image: "/images/home/cat_1.jpg",
    name: "Maltas",
    color: "yellow",
    link: "/productos/Maltas",
  },
  {
    image: "/images/home/cat_2.jpg",
    name: "Lúpulos",
    color: "orange",
    link: "/productos/Lupulos",
  },
  {
    image: "/images/home/cat_3.jpg",
    name: "Levaduras",
    color: "blue",
    link: "/productos/Levaduras",
  },
  {
    image: "/images/home/cat_4.jpg",
    name: "OUTLET",
    color: "light-gray",
    link: "/productos/Levaduras",
  },
];
const Categories = () => {
  return (
    <section className="categories">
      <Container className="mt-5">
        <Row>
          {cat_data.map((item, ind) => (
            <Category
              image={item.image}
              name={item.name}
              color={item.color}
              link={item.link}
            />
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Categories;

const Category = ({
  ind,
  image,
  name,
  color,
  link,
}: {
  [key: string]: any;
}) => (
  <Col md={3} className="col-6" key={ind}>
    <Link to={link}>
      <div className="category mb-4">
        <img src={image} alt="Mi cerveza" />
        <div className="category-content px-3 py-4">{name}</div>
        <div className={`color-badge ${color}`}></div>
      </div>
    </Link>
  </Col>
);
