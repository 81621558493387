import axios from "axios";
import { WordpressPost } from "../models/static_pages";

const url = "https://info.micervesa.mx/index.php/wp-json/wp/v2/";

export async function getPost(
  post_id: string | number
): Promise<WordpressPost | false> {
  try {
    const { data }: { data: WordpressPost } = await axios.get(
      `${url}posts/${post_id}`
    );
    return data;
  } catch (error) {
    console.log("wordpress_api.tsx:6 | error ", error);
    return false;
  }
}
