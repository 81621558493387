import React from "react";
import Product from "../../components/product";
import { Container, Row } from "react-bootstrap";
import { UserType } from "../../models/user_model";
import { FiltersBrands as FilterType } from "../../hooks/useFilters";
import useProducts from "../../hooks/useProducts";

type ProductsProps = {
  brand_name: string;
  filters: FilterType;
};

const Products: React.FC<ProductsProps> = ({ brand_name, filters }) => {
  const user: UserType = JSON.parse(localStorage.getItem("user") || "{}");
  const selectedTypes = filters.type.selected;
  const selectedFamilies = filters.family.selected;
  const elements = [...selectedTypes, ...selectedFamilies];

  const processedElements = elements
    .map((element) => {
      return element;
    })
    .filter(Boolean); // Removes falsy values like '', 0, null, and undefined.

  const filteredElements = processedElements.filter(
    (element): element is string => element !== undefined
  );
  const uniqueGroups: string[] = [...new Set(filteredElements)];
  const grpsbi = uniqueGroups;
  const { result: products } = useProducts({
    brands: [brand_name],
    grpsbi,
    size: filters.size.selected[0],
  });
  return (
    <div className="products">
      <Container>
        <Row className="">
          {!products || products.length === 0
            ? "Loading..."
            : products.map((item, ind) => (
                <Product
                  key={`product_${ind}-${item.id}`}
                  product={item}
                  user={user}
                />
              ))}
        </Row>
      </Container>
    </div>
  );
};

export default Products;
