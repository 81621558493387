import React from "react";
import { useHistory } from "react-router-dom";
import { SimilarProduct } from "../../models/product";

type SimilarProps = {
  products: SimilarProduct[];
  selected: string;
};

const Similar: React.FC<SimilarProps> = ({ products, selected }) => {
  const history = useHistory();

  const ordered = products.sort((a, b) => a.size.localeCompare(b.size));

  const handleChange = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    history.push(`/producto/${ev.target.value}`);
  };

  return (
    <div className="row mb-3">
      <div className="col-md-5">
        <p className="m-0">Tamaño:</p>
        <select
          value={selected}
          className="form-control"
          onChange={handleChange}
        >
          {ordered.map((product) => (
            <option
              key={`similar_product_${product.product_id}`}
              value={product.product_id}
            >
              {product.title}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default Similar;
