import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Col } from "react-bootstrap";
import PaymentForm from "./payment-form";
// import axios from "../../middleware/axios";

import { Select } from "../../components/input";
import { CardData } from "../../models/checkout_model";
import { useParams } from "react-router-dom";
import { OrderDbType, OrderPrductItem } from "../../models/order_model";
import { getOrderApi } from "../../middleware/order-functions";
import { formatNumber } from "../../middleware/common-functions";
import { AddressForm } from "../checkout/address_page";
import { AddressType } from "../../models/address";
import { fauth } from "../../middleware/firebase";
import { getAddresses } from "../../middleware/user-functions";

declare global {
  interface Window {
    Payment: any;
  }
}

const PaymentPage: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [prevAddress, setPrevAddress] = useState<AddressType[] | false>(false);
  const [card, setCard] = useState<CardData>({} as CardData);
  const { order_id } = useParams<{ order_id: string }>();
  const [order, setOrder_data] = useState<OrderDbType | null>();
  const [user] = useAuthState(fauth);

  useEffect(() => {
    getOrder(order_id);
    getAddressesFun();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order_id]);
  const getAddressesFun = async () => {
    if (user) {
      const addresses = getAddresses(user);
      if (addresses) {
        setPrevAddress(addresses);
      }
    }
  };
  const getOrder = async (order_id: string) => {
    const set_order = await getOrderApi(order_id);
    if (set_order) {
      setOrder_data({ ...set_order });
    }
    setLoading(false);
  };

  const handleCard = (type: keyof CardData, value: string) => {
    console.log("index.tsx:200 | type", type);
    if (!value) return false;
    const set_card = { ...card };
    set_card[type] = value;
    setCard(set_card);
  };

  const handleAddresType = (ev: { target: HTMLInputElement }) => {
    const value = ev.target.value;
    const type = ev.target.id as keyof AddressType;
    if (!type || typeof type === "undefined") return false;
    const set_card = { ...card };
    if (!set_card.address || typeof set_card.address === "undefined") {
      set_card.address = {} as AddressType;
    }
    //@ts-ignore next-line
    set_card.address[type] = value;
    setCard(set_card);
  };

  const setSelectedAddress = (set_section: AddressType | false) => {
    const set_card = { ...card };
    if (!set_section) {
      delete set_card.address;
    } else {
      set_card.address = set_section;
    }
    setCard(set_card);
  };

  const handleSelectAddress = (ev: { target: HTMLSelectElement }) => {
    const value = ev.target.value;
    if (prevAddress === false) {
      return;
    }
    if (value === "false") {
      setSelectedAddress(false);
      return;
    }
    ///get select address
    const selectedAddress = prevAddress?.find(
      (address) => address.id === value
    ) as AddressType;
    if (!selectedAddress) {
      return;
    }
    setSelectedAddress(selectedAddress);
  };

  const handleMakePayment = async () => {
    console.log("handleMakePayment");
    if (!user || !order) {
      return;
    }

    const paymentData = {
      merchantId: "9396788",
      name: user.uid,
      password: "rQbg5.knw8DdXtP", ///micervesamx
      mode: "AUT", ///PRD or AUT
      controlNumber: order_id,
      terminalId: "93967881",
      amount: order.totals.total,
      merchantName: "Mi Cervesa MX", //MALTAS E INSUMOS CERV
      merchantCity: "CHIHUAHUA",
      lang: "EN",
    };

    try {
      const response = await window.Payment.startPayment(paymentData);
      console.log("index.tsx:118 | response", response);
    } catch (error) {
      console.log("index.tsx:99 | error ", error);
    }

    // try {
    //   const response = await axios.post("/paymentMake", {
    //     order_id,
    //     user_id: user?.uid,
    //     // source: card.token,
    //   });
    //   console.log("index.tsx:99 | ", response);
    // } catch (error) {
    //   console.log("index.tsx:99 | error ", error);
    // }
  };

  if (loading || !order) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="first-container last-container payment_page container">
      <h2>Paga tu orden {order_id}</h2>
      <div className="row">
        <div className="col-md-6">
          <PaymentForm handleCard={handleCard} cardData={card} />
          {prevAddress && prevAddress.length ? (
            <Col md={12}>
              <Select
                id="state"
                label="Direcciones Guardadas"
                placeholder="Selecciona una dirección"
                value={card.address?.id}
                onChange={handleSelectAddress}
                options={prevAddress.map((address) => ({
                  value: address.id!,
                  label: `${address.address_name} | ${address.address} ${address.city}`,
                }))}
              />

              <hr />
            </Col>
          ) : null}
          <AddressForm
            handleType={handleAddresType}
            deliveryData={card.address}
            showAditionalData={false}
          />
          <button
            className="btn btn-warning btn-block text-left mt-3"
            onClick={handleMakePayment}
          >
            <i className="far fa-credit-card mr-2"></i> Realizar Pago
          </button>
        </div>

        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h4>Resumen de la orden</h4>
              <Products data={order.products} />

              <div className="d-flex justify-content-between">
                <span>Total</span>
                <span>${formatNumber(order.totals.total)}</span>
              </div>
              <hr />
              {order.totals.shipping ? (
                <div className="d-flex justify-content-between">
                  <span>Envío</span>
                  <span>${formatNumber(order.totals.shipping)}</span>
                </div>
              ) : null}
              <div className="d-flex justify-content-between">
                <span>Subtotal</span>
                <span>${formatNumber(order.totals.subtotal)}</span>
              </div>
              <div className="d-flex justify-content-between">
                <span>IVA</span>
                <span>${formatNumber(order.totals.tax || 0)}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

type ProductsProps = {
  data: OrderPrductItem[];
};
const Products: React.FC<ProductsProps> = ({ data }) => {
  return (
    <table className="table table-striped ">
      <thead>
        <tr>
          <th scope="col">Producto</th>
          <th scope="col">Qty</th>
          <th scope="col">Precio</th>
        </tr>
      </thead>
      {data.map((product) => (
        <tr key={`table_${product.id}`} className="">
          <th scope="row">
            <a
              href={`/producto/${product.id}`}
              target="_blank"
              rel="noreferrer"
            >
              {product.title}
            </a>
          </th>
          <td>{product.qty}</td>
          <td>${formatNumber(product.price)}</td>
        </tr>
      ))}
    </table>
  );
};

export default PaymentPage;
