import React from "react";
import MainHeader from "./main/header";
import Header from "./checkout/header";
import Footer from "./checkout/footer";
import MainFooter from "./main/footer";
import MobileNav from "./main/headermob";

import { useLocation } from "react-router-dom";
import Legals from "./legals";

const Layout = (props: any) => {
  let loc = useLocation();
  let layout = "";
  if (loc.pathname === "/checkout" || loc.pathname === "/carrito") {
    layout = "checkout";
  }
  if (layout === "checkout") {
    return (
      <React.Fragment>
        <Header />
        {props.children}
        <Footer />
        <Legals />
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <MainHeader />
      <MobileNav />
      {props.children}
      <MainFooter />
      <Legals />
    </React.Fragment>
  );
};

export default Layout;
