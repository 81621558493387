// import { db } from "../../middleware/firebase";
import Loader from "../../components/utils/loader";
import Order from "./order";
import useUserCollection from "../../hooks/useUserCollection";
// import useUserAuth from "../../hooks/useUserAuth";

const Orders = ({ order_id }: { order_id: string }) => {
  const { data: order, loading } = useUserCollection({
    collection: "quotes",
    itemId: order_id,
  });
  // const { user } = useUserAuth();
  // const [order, setOrder] = useState(null);
  // const [loading, setLoading] = useState(true);
  // const user = JSON.parse(localStorage.getItem("user") || "");
  // useEffect(() => {
  //   getOrders();
  // }, []);
  // const getOrders = async () => {
  //   try {
  //     const order_db = await db
  //       .collection("quotes")
  //       .doc(user.id)
  //       .collection("quotes")
  //       .doc(order_id)
  //       .get();

  //     if (!order_db.exists) {
  //     } else {
  //       setOrder(order_db.data());
  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     console.log("Error getting documents: ", error);
  //   }
  // };

  // const sendToCart = async () => {
  //   const batch = db.batch();
  //   const collection = db.collection("cart").doc(user.id);
  //   if (order.api_id) {
  //     batch.set(collection, { api_id: order.api_id }, { merge: true });
  //   }
  //   order.products.forEach((item: any) => {
  //     const productRef = collection.collection("products").doc(item.id);
  //     batch.set(productRef, { ...item });
  //   });

  //   await batch.commit();

  //   window.location.href = "/cart";
  // };

  return (
    <div className="my_orders">
      <div className="card  material-shadows mb-3">
        <div className="card-body">
          {loading ? (
            <Loader />
          ) : (
            <Order
              order_data={order}
              backUrl="/cuenta/cotizaciones"
              type="quotes"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Orders;

// export default function Order(props: any) {
//   console.log(props);
//   return <div>Quote</div>;
// }
