import React, { useEffect, useState } from "react";
import Products from "./products";
import { Link } from "react-router-dom";
import { useLocation, useHistory, useParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
} from "react-bootstrap";
import Filters from "./filters";
import useFilters, {
  getFilterData,
  Filters as FilterType,
} from "../../hooks/useFilters";
import useProducts from "../../hooks/useProducts";

const Category = () => {
  const history = useHistory();
  const [activeFilters, setActiveFilters] = useState<any[]>([]);
  const loading = false;
  const [filterData, setFilterData] = useState(getFilterData());
  // const [pageCount, setPageCount] = useState(0);
  const query = useQuery();
  const limit = 50;
  const [pagination, setPagination] = useState(1);
  const { primary, secondary, tertiary } = useParams<{
    primary?: string;
    secondary?: string;
    tertiary?: string;
  }>();
  const urlTypes = [
    primary ? replaceWords(primary).split("-").join(" ") : "",
    secondary ? secondary.split("-").join(" ") : filterData.family.selected[0],
    tertiary ? tertiary.split("-").join(" ") : filterData.type.selected[0],
  ];
  const replacedUrlTypes = urlTypes.map((word) =>
    word ? replaceWords(word) : word
  );
  const { result: filters } = useFilters(replacedUrlTypes);

  useEffect(() => {
    setPagination(1);
    setFilterData(getFilterData());
    setActiveFiltersFromFilterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primary, secondary, tertiary]);

  ///everytime new products arrive, update filters
  useEffect(() => {
    if (filters) {
      // Create a deep clone of filters to avoid direct mutation
      const newFilters = { ...filters };
      (
        ["family", "type", "brands", "size", "origin"] as (keyof FilterType)[]
      ).forEach((key) => {
        if (newFilters[key] && filterData[key]) {
          newFilters[key].selected = filterData[key].selected.filter(
            (elem) => elem && newFilters[key].elements.includes(elem)
          );
        }
      });
      setFilterData(newFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const setActiveFiltersFromFilterData = () => {
    const active_arr: any[] = [];
    Object.keys(filterData).forEach((key) => {
      const a_fliter = query.get(key);
      if (a_fliter) {
        active_arr.push({ key, value: a_fliter });
        triggerFilterUpdate(
          a_fliter,
          key as keyof FilterType,
          "checkbox",
          false
        );
      }
    });
    setActiveFilters([...active_arr]);
  };

  const brands = filterData.brands.selected;
  const type_url = [tertiary, secondary, primary];
  const querySelector = type_url
    .map((i) => {
      if (i) {
        return i.split("-").join(" ");
      }
      return i;
    })
    .find((i) => {
      return i;
    }); ///send only last type;

  // Extract selected types and families, and include the querySelector.
  const selectedTypes = filterData.type.selected;
  const selectedFamilies = filterData.family.selected;
  const elements = [...selectedTypes, ...selectedFamilies, querySelector];

  // Replace words and filter out any falsy values.
  const processedElements = elements
    .map((element) => {
      return element ? replaceWords(element) : element;
    })
    .filter(Boolean); // Removes falsy values like '', 0, null, and undefined.

  // Filter out undefined values from processedElements
  const filteredElements = processedElements.filter(
    (element): element is string => element !== undefined
  );

  // Remove duplicates by converting to a Set and back to an array.
  const uniqueGroups: string[] = [...new Set(filteredElements)];

  // Assign the result to 'grpsbi'.
  const grpsbi = uniqueGroups;
  const { result: products } = useProducts({
    brands,
    grpsbi,
    limit: limit,
    pagination,
    urlTypes,
    size: filterData.size.selected[0],
    additionalAttributes: filterData.origin.selected[0],
  });

  const triggerFilterUpdate = (
    value: string,
    key: keyof FilterType,
    type: string,
    update_url: boolean = true
  ) => {
    let activeFilters_current = [...activeFilters];
    const filterData_current = { ...filterData };
    const selectedElements = filterData_current[key].selected;
    const isSelected = selectedElements.includes(value);

    if (type === "price" /* Mutually exclussive */) {
      if (isSelected) {
        filterData_current[key].selected = [];
      } else {
        filterData_current[key].selected = [value];
      }
    } /* type === "checkbox" */ else {
      if (isSelected) {
        const filtered = selectedElements.filter((v) => v !== value);
        filterData_current[key].selected = filtered;
        activeFilters_current = activeFilters_current.filter(
          (f) => f.key !== key
        );
      } else {
        selectedElements.push(value);
        activeFilters_current.push({ key, value });
      }
    }
    let filter_params_url = "";
    if (activeFilters_current.length) {
      filter_params_url = activeFilters_current
        .map((i) => `${i.key}=${i.value}`)
        .join("&");
    }
    if (update_url) {
      history.push({
        search: filter_params_url,
      });
    }
    setActiveFilters([...activeFilters_current]);
    setFilterData(filterData_current);
  };

  const updateFilters = (key: keyof FilterType) => {
    return (value: string, type: string) => {
      return triggerFilterUpdate(value, key, type);
    };
  };
  return (
    <div className="category-page pb-5">
      <Container>
        <BreadcrumbSection
          primary={primary}
          secondary={secondary}
          tertiary={tertiary}
        />
        <Row>
          <Col md={2}>
            {filterData ? (
              <Filters filters={filterData} updateFilters={updateFilters} />
            ) : null}
          </Col>
          <Col md={10}>
            <Products products={products} loading={loading} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

interface BreadcrumbSectionProps {
  primary?: string;
  secondary?: string;
  tertiary?: string;
}

const BreadcrumbSection: React.FC<BreadcrumbSectionProps> = ({
  primary,
  secondary,
  tertiary,
}) => (
  <Breadcrumb as="ul" className="p-0">
    {primary && (
      <BreadcrumbItem>
        <Link to={"/productos/" + primary}>{primary.split("-").join(" ")}</Link>
      </BreadcrumbItem>
    )}
    {secondary && (
      <BreadcrumbItem>
        <Link to={"/productos/" + primary + "/" + secondary}>
          {secondary.split("-").join(" ")}
        </Link>
      </BreadcrumbItem>
    )}
    {tertiary && (
      <BreadcrumbItem active>
        <Link
          to={
            "/productos/" +
            primary +
            "/" +
            secondary +
            "/" +
            tertiary.split("-").join(" ")
          }
        >
          {tertiary}
        </Link>
      </BreadcrumbItem>
    )}
  </Breadcrumb>
);

function replaceWords(word: string) {
  // word = word.split(" ").join("-");
  // word = encodeURIComponent(word);
  return word.replace("Bano", "Baño");
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default Category;
