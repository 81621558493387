import React from "react";
import Hero from "./hero";
import Categories from "./categories";
import FeaturedProducts from "./featured";

const HomePage: React.FC = () => (
  <div className="home">
    <Hero />
    <Categories />
    <FeaturedProducts />

    {/* {`{ Home page component }`} <br />
    <Link className="btn btn-primary m-2" to="/producto">
      Pagina de producto
    </Link>
    <Link className="btn btn-primary m-2" to="/lista">
      Pagina de lista
    </Link>*/}
  </div>
);

export default HomePage;
