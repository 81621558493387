import React from "react";

const QuantityInput = ({
  qty,
  handlePlus,
  handleMinus,
  className,
  iconPlus,
  iconMinus,
}: {
  [key: string]: any;
}) => {
  return (
    <div className="quantity_input">
      <button className="btn-minus" onClick={handleMinus}>
        <i className={iconMinus}></i>
      </button>
      <input
        className={className}
        name="quantity"
        data-type="input"
        value={qty}
      />

      <button className="btn-plus" onClick={handlePlus}>
        <i className={iconPlus}></i>
      </button>
    </div>
  );
};

export default QuantityInput;
