import { useAsync } from "react-async-hook";
import { getPrice } from "../middleware/iva";
import { UserType } from "../models/user_model";
import { formatNumber } from "../middleware/common-functions";
import { db } from "../middleware/firebase";
import { Product, SimilarProduct } from "../models/product";

// function sleep(ms: number) {
//   return new Promise((resolve) => setTimeout(resolve, ms));
// }

export async function getProduct(productId: string) {
  let product = db.collection("products").doc(productId);
  const ret_product = await product.get();
  if (ret_product.exists) {
    const product_doc = (ret_product.data() || {}) as Product;
    const user: UserType = JSON.parse(localStorage.getItem("user") || "{}");
    try {
      const similar = await getSimilar(product_doc.aliasd);
      product_doc.similar = similar;
    } catch (error) {
      product_doc.similar = [];
      console.log("useProduct.ts:38 | error", error);
    }
    if (!user.priceListId) {
      return product_doc;
    }
    if (!user || !product_doc.prices) {
      product_doc.price = "NOPRICE";
      return product_doc;
    }
    const { price, regular_price } = getPrice(
      product_doc.prices,
      product_doc.fconvs
    );
    if (price === "NOPRICE") {
      product_doc.price = "NOPRICE";
      return product_doc;
    }
    product_doc.price_num = price;
    product_doc.regular_price = regular_price;

    product_doc.price = formatNumber(product_doc.price_num);
    return product_doc;
  } else {
    return false;
  }
}

export async function getSimilar(alias: string): Promise<SimilarProduct[]> {
  const db_data = await db.collection("product-alias").doc(alias).get();
  if (db_data.exists === false) {
    return [];
  } else {
    const product = db_data.data() || {};
    const similar: SimilarProduct[] = product.products.map((product: any) => {
      return {
        product_id: product.product_id,
        size: product.size,
        title: product.title,
      };
    });
    return similar;
  }
}

export default function useProduct(productId: string) {
  return useAsync(() => getProduct(productId), [productId]);
}
