import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { formatNumber } from "../middleware/common-functions";
import { ProductXiuds, FromType } from "../models/product_model";
import { CantInput } from "./product-block";

type AddProductProps = {
  cant_hidd?: boolean;
  promptAdd?: any;
  block?: boolean;
  amount?: number;
  product_id: string;
  surface?: ProductXiuds[];
  added?: boolean;
  price: string;
  suggest_amount?: number;
  badge?: boolean;
  from?: FromType;
};

type SurfaceDataProps = {
  surface_data: ProductXiuds[];
  price: string;
  badge: boolean;
};

type AddButtonProps = {
  promptAdd: any;
  block?: boolean;
  added?: boolean;
  product_id: string;
};

const AddProduct: React.FC<AddProductProps> = ({
  cant_hidd,
  promptAdd,
  block,
  amount,
  product_id,
  surface,
  added,
  price,
  suggest_amount,
  badge,
  from,
}) => {
  const [current_amount, setCurrentAmount] = useState(1);

  useEffect(() => {
    if (suggest_amount) {
      setCurrentAmount(suggest_amount);
    }
  }, [suggest_amount]);

  const handleAmount = (ev: { currentTarget: HTMLButtonElement }) => {
    const input_product_id = ev.currentTarget.dataset.product;
    const type = ev.currentTarget.dataset.type;
    let value;
    if (type === "input") {
      value = ev.currentTarget.value;
    } else {
      const inputEl = document.getElementById(
        `cant_input_${input_product_id}`
      ) as HTMLInputElement;
      value = parseInt(inputEl!.value);
      if (type === "btn_plus") {
        value++;
      } else {
        value--;
      }
    }

    setCurrentAmount(Number(value));
  };
  if (price === "" || price === "0") {
    return (
      <span>
        No disponible <br />
      </span>
    );
  }
  return (
    <div className="add_product">
      {surface && (
        <SurfaceData
          surface_data={surface}
          price={price}
          badge={badge || false}
        />
      )}
      {!cant_hidd && <p>Cantidad:</p>}
      <div className="row">
        <div className={`${block ? "col-md-12" : "col-md-6"}`}>
          {surface && promptAdd ? getSurface(surface, current_amount) : null}
          {promptAdd && (
            <CantInput
              refTag={amount}
              cant={current_amount}
              changeFun={handleAmount}
              from={from}
              product_id={product_id}
            />
          )}
        </div>

        <div className={`mt-3 ${block ? "col-md-12" : "col-md-6"}`}>
          {promptAdd ? (
            <AddButton
              promptAdd={promptAdd}
              block={block}
              added={added}
              product_id={product_id}
            />
          ) : (
            <Link
              to={`/product/${product_id}`}
              className={`btn  xs_block ${block && "btn-block"} ${
                added ? "btn-light" : "btn-cart"
              }`}
            >
              Ver Más
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

const AddButton: React.FC<AddButtonProps> = ({
  promptAdd,
  block,
  added,
  product_id,
}) => (
  <button
    className={`btn  xs_block ${block && "btn-block"} ${
      added ? "btn-light" : "btn-cart"
    }`}
    onClick={added ? null : promptAdd}
    data-product_id={product_id}
  >
    {added ? (
      <React.Fragment>
        Agregado <i className="fas fa-check ml-1"></i>
      </React.Fragment>
    ) : (
      <React.Fragment>
        Agregar <i className="fas fa-cart-plus ml-1"></i>
      </React.Fragment>
    )}
  </button>
);

function getSurface(data: ProductXiuds[], amount: number | string) {
  amount = Number(amount);
  ////data comes from product.xiuds
  const surface = data.find((i) => {
    return i.idunmd === "mt2";
  });
  ///run only on mt2 values
  if (!surface) {
    return false;
  }
  let surface_area;
  if (amount) {
    surface_area = (
      <span className="meters">
        {formatNumber(surface.fconvs * amount, true)}m<sup>2</sup>
      </span>
    );
  } else if (amount === 0) {
    surface_area = "-";
  } else {
    surface_area = (
      <span className="meters">
        {formatNumber(surface.fconvs, true)}m<sup>2</sup>
      </span>
    );
  }
  return (
    <span className="surface_info">
      <span className="amount_data">
        {" "}
        <i className="far fa-lightbulb"></i> Con{" "}
        {amount ? formatNumber(amount, false) : 1} caja
        {amount > 1 ? "s " : " "}
      </span>
      cubres <b>{surface_area}</b>
    </span>
  );
}

const SurfaceData: React.FC<SurfaceDataProps> = ({
  surface_data,
  price,
  badge,
}) => {
  const surface = surface_data.find((i) => {
    return i.idunmd === "mt2";
  });
  ///run only on mt2 values
  if (!surface) {
    return null;
  }
  return (
    <p className="surface_data">
      <span className={`${badge && "badge badge_main bold"}`}>
        {" "}
        ${formatNumber(Number(price) / surface.fconvs, true)}
        <span className="meters">
          /m<sup>2</sup>
        </span>{" "}
      </span>
      <span className={`${badge && "badge badge_main"}`}>
        {" "}
        {surface.fconvs}
        <span className="meters">
          m<sup>2</sup> /caja
        </span>
      </span>
    </p>
  );
};

export default AddProduct;
