import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";

import Products from "./products";
import Filters from "../category/filters";
import {
  getFilterDataBrands,
  useFiltersBrands,
  FiltersBrands as FilterType,
} from "../../hooks/useFilters";

const BrandsPage: React.FC = () => {
  const history = useHistory();
  const [activeFilters, setActiveFilters] = useState<any[]>([]);
  const [filterData, setFilterData] = useState(getFilterDataBrands());
  const { brand_name } = useParams<{ brand_name: string }>();
  const { result: filters } = useFiltersBrands(brand_name);

  useEffect(() => {
    if (filters) {
      // Create a deep clone of filters to avoid direct mutation
      const newFilters = { ...filters };
      (["family", "type", "size", "origin"] as (keyof FilterType)[]).forEach(
        (key) => {
          if (newFilters[key] && filterData[key]) {
            newFilters[key].selected = filterData[key].selected.filter(
              (elem) => elem && newFilters[key].elements.includes(elem)
            );
          }
        }
      );
      setFilterData(newFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const triggerFilterUpdate = (
    value: string,
    key: keyof FilterType,
    type: string,
    update_url: boolean = true
  ) => {
    let activeFilters_current = [...activeFilters];
    const filterData_current = { ...filterData };
    const selectedElements = filterData_current[key].selected;
    const isSelected = selectedElements.includes(value);

    if (type === "price" /* Mutually exclussive */) {
      if (isSelected) {
        filterData_current[key].selected = [];
      } else {
        filterData_current[key].selected = [value];
      }
    } /* type === "checkbox" */ else {
      if (isSelected) {
        const filtered = selectedElements.filter((v) => v !== value);
        filterData_current[key].selected = filtered;
        activeFilters_current = activeFilters_current.filter(
          (f) => f.key !== key
        );
      } else {
        selectedElements.push(value);
        activeFilters_current.push({ key, value });
      }
    }
    let filter_params_url = "";
    if (activeFilters_current.length) {
      filter_params_url = activeFilters_current
        .map((i) => `${i.key}=${i.value}`)
        .join("&");
    }
    if (update_url) {
      history.push({
        search: filter_params_url,
      });
    }
    setActiveFilters([...activeFilters_current]);
    setFilterData(filterData_current);
  };

  const updateFilters = (key: keyof FilterType) => {
    return (value: string, type: string) => {
      return triggerFilterUpdate(value, key, type);
    };
  };

  return (
    <div className="category-page pb-5">
      <Container>
        <Row>
          <Col md={2}>
            {filterData ? (
              //@ts-ignore next-line
              <Filters filters={filterData} updateFilters={updateFilters} />
            ) : null}
          </Col>
          <Col md={10}>
            <h1 className="underlined my-4 ">Productos de {brand_name}</h1>
            <Products brand_name={brand_name} filters={filterData} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BrandsPage;
