import React from "react";
import { CartProductType } from "../../../models/product";

type CartProductProps = {
  product: CartProductType;
};
const CartProduct: React.FC<CartProductProps> = ({ product }) => {
  return (
    <div key={product.idpart} className="cart-product mt-2 mb-2">
      <div className="img-container">
        <img
          src={
            product.images && product.images.length
              ? product.images[0]
              : "https://via.placeholder.com/100"
          }
          alt={product.descp1}
        />
      </div>
      <div>
        <div className="product_name">{product.descp1}</div>
        <div className="product_qty">Cantidad: {product.qty}</div>
        <div className="product_clave">Código: {product.idpart}</div>
        <div className="product_price">MXN ${product.price}</div>
      </div>
    </div>
  );
};

export default CartProduct;
