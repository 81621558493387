import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ContactForm from "./contact";

const ClientPage: React.FC = () => {
  return (
    <section className="client pb-5">
      <Container>
        <Row className="mb-5">
          <Col>
            <h3>REQUISITOS PARA CLIENTES DE MAYOREO</h3>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <div className="client-item">
              <img src="/images/client/client-1.png" alt="Mi Cerveza" />

              <div className="p-4">
                1. Alta en hacienda como cervecería o tienda
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="client-item">
              <img src="/images/client/client-2.png" alt="Mi Cerveza" />

              <div className="p-4">
                2. Contar con diseño de logotipo de cervecería o tienda
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="client-item">
              <img src="/images/client/client-3.png" alt="Mi Cerveza" />

              <div className="p-4">
                3. Compra mínima de <br></br>$10,000 MXN más IVA
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <ContactForm />
        </Row>
      </Container>
    </section>
  );
};

export default ClientPage;
