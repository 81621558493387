import React from "react";
//@ts-ignore next-line
import CreditCardInput from "react-credit-card-input";

import Input from "../../components/input";
import { Row } from "react-bootstrap";
import { CardData } from "../../models/checkout_model";

type PaymentFormProps = {
  handleCard: (type: keyof CardData, value: string) => void;
  cardData?: CardData;
};
const PaymentForm: React.FC<PaymentFormProps> = ({ handleCard, cardData }) => (
  <div className="credit-card">
    <Row>
      <Input
        id="card_name"
        onChange={(ev) => {
          handleCard("name", ev.target.value);
        }}
        value={cardData?.name || ""}
        className="col-md-12"
        label="Nombre en la Tarjeta"
        type="text"
        name="card_"
      />
    </Row>
    <CreditCardInput
      containerClassName="credit-card-input"
      inputClassName="card-input"
      customTextLabels={{
        invalidCardNumber: "El número de la tarjeta no es correcto",
        expiryError: {
          invalidExpiryDate: "La fecha de expiración es no es correcta",
          monthOutOfRange: "El mes de expiración debe estar entre 01 y 12",
          yearOutOfRange: "Tarjeta vencida",
          dateOutOfRange: "Tarjeta vencida",
        },
        invalidCvc: "El código de seguridad es inválido",
        invalidZipCode: "El código postal es inválido",
        cardNumberPlaceholder: "Número de tarjeta",
        expiryPlaceholder: "MM/AA",
        zipPlaceholder: "C.P.",
      }}
      cardNumberInputProps={{
        value: cardData?.number || "",
        onChange: (ev: any) => {
          handleCard("number", ev.target.value);
        },
      }}
      cardExpiryInputProps={{
        value: cardData?.date || "",
        onChange: (ev: any) => {
          handleCard("date", ev.target.value);
        },
      }}
      cardCVCInputProps={{
        value: cardData?.cvv || "",
        onChange: (ev: any) => {
          handleCard("cvv", ev.target.value);
        },
      }}
    />
  </div>
);

export default PaymentForm;
