import React, { useState } from "react";
import { Filters as FilterType } from "../../hooks/useFilters";

type FilterProps = {
  filters: FilterType;
  updateFilters: any;
};

const Filters: React.FC<FilterProps> = ({ filters, updateFilters }) => (
  <div className="filters">
    {Object.keys(filters).map((key) => {
      if (key in filters) {
        const filterKey = key as keyof FilterType;
        const { title, elements, type, selected, value } = filters[filterKey];
        return (
          <Filter
            key={key}
            title={title}
            elements={elements}
            type={type}
            selected={selected}
            value={value}
            updateValue={updateFilters(filterKey)}
          />
        );
      }
      return null;
    })}
  </div>
);

type FilterItemProps = {
  title: string;
  elements: string[];
  type: string;
  selected: string[];
  value?: string;
  updateValue: any;
};

const Filter: React.FC<FilterItemProps> = ({
  title,
  elements,
  type,
  selected,
  updateValue,
}) => {
  const [showMore, setShowMore] = useState(false);
  if (elements.length <= 1 && selected.length === 0) {
    ///return null if not selected
    return null;
  }
  return (
    <div className="filter mb-3">
      <h5> {title}</h5>
      <div className={`filter_container ${showMore && "expanded"}`}>
        {
          <>
            {elements.map((value, i) => (
              <Checkbox
                key={value + i}
                value={value}
                isSelected={selected.includes(value)}
                onClick={() => updateValue(value, type)}
                type={type}
              />
            ))}
          </>
        }
      </div>
      {elements.length > 6 && (
        <button
          className="btn btn-link show_more_btn px-0 text-capitalize text-info"
          onClick={() => setShowMore(!showMore)}
        >
          {showMore ? "Mostar Menos" : "Mostar Más"}
        </button>
      )}
    </div>
  );
};

const Checkbox = ({ value, isSelected, onClick, type }: any) => (
  <button
    className={`btn btn-block button-transparent text-left p-0 filter_item text-capitalize ${
      isSelected && "selected"
    }`}
    onClick={onClick}
  >
    <i
      className={`${isSelected ? "icon-square text-success" : "icon-square-o"}`}
    ></i>
    {type === "price" && "$"}
    {type === "price"}
    {type === "price" && " MXN"} {value}
  </button>
);

export default Filters;
