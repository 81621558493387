import React, { useState, useEffect } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useAuthState } from "react-firebase-hooks/auth";

import useProducts from "../../hooks/useProducts";
import { db, fauth } from "../../middleware/firebase";
import { CartProductType } from "../../models/product";
import DeliverySection from "./delivery_section";
import AddressSection from "./address_section";
import CartCheckout from "./cart-products";
import PaymentSection from "./payment";
import ResumeSection from "./resume_section";
import ThankYouSection from "./thankyou";
import { Container } from "react-bootstrap";
import CheckoutBreadcrumbs from "./breadcrums";
import {
  DeliveryTypes,
  ErrorType,
  OrderType,
  PaymentTypes,
  SectionTypes,
  section_types,
} from "../../models/checkout";
import { AddressType } from "../../models/address";
import { getTotals } from "../../middleware/total";
import BranchSelection from "./branch_selection";
import { ZoneItem } from "../../models/branch_model";

const CheckoutPage: React.FC = () => {
  const [section, setSection] = useState<SectionTypes>("delivery");
  const [loading, setLoading] = useState<boolean>(false);
  const [cart_products, setCart_products] = useState<null | CartProductType[]>(
    null
  );
  const [branches, setBranches] = useState<ZoneItem[]>([]);
  const [error, setError] = useState<ErrorType | null>(null);
  const [order, setOrder] = useState<OrderType>({} as OrderType);
  const [user] = useAuthState(fauth);
  const [cartProductDocs] = useCollectionData(
    db
      .collection("cart")
      .doc(user ? user.uid : "UXIONO")
      .collection("products")
  );
  const { result: products } = useProducts(
    {
      ids: (cartProductDocs || []).map(({ id }) => id) as any,
    },
    !Boolean(cartProductDocs && cartProductDocs.length)
  );

  useEffect(() => {
    getBranches();
  }, []);

  useEffect(() => {
    console.log("index.tsx:77 | order", order);
  }, [order]);

  useEffect(() => {
    if (products && cartProductDocs) {
      const set_order = { ...order };
      if (cartProductDocs.length === 0) {
        return;
      }
      const set_cart_products = products.map((p) => ({
        ...p,
        qty: (cartProductDocs || []).filter((x) => x.id === p.id)[0].qty,
      }));
      ///get total
      const total = getTotals(
        (products || []).map((p) => ({
          ...p,
          qty:
            ((cartProductDocs || []).filter((x) => x.id === p.id)[0] || {})
              .qty || 0,
        })) as any
      );
      setCart_products(set_cart_products);
      setOrder({
        ...set_order,
        totals: total,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products, cartProductDocs]);

  const handleSections = (set_section: SectionTypes) => {
    if (!sectionValidation()) {
      return;
    }
    setSection(set_section);
  };

  const getBranches = () => {
    db.collection("branches")
      .get()
      .then((querySnapshot) => {
        const branches_obj: ZoneItem[] = [];
        querySnapshot.forEach((doc) => {
          branches_obj.push(doc.data() as ZoneItem);
        });
        setBranches(branches_obj);
      });
  };

  const selectBranch = (ev: { currentTarget: HTMLElement }) => {
    const value = ev.currentTarget.dataset.value;
    if (!value || value === order.selectedBranch?.id) return false;
    ///select active branch from branches
    const selectedBranch = branches
      .map((zone) => zone.xiams.filter((branch) => branch.id === value)[0])
      .filter((branch) => branch)[0];
    if (!selectedBranch) {
      return;
    }
    setOrder({
      ...order,
      selectedBranch,
    });
  };

  const sectionValidation = (): boolean => {
    switch (section) {
      case "delivery":
        return !order.delivery_type ? false : true;
      case "address":
        return addressValidation();
      default:
        return true;
    }
  };

  const addressValidation = (): boolean => {
    if (!order.address) {
      setError({ message: "Favor de Agregar tu dirección" });
      return false;
    }
    if (!order.address.name) {
      setError({ message: "Favor de Agregar tu nombre" });
      return false;
    }
    if (!order.address.lastname) {
      setError({ message: "Favor de Agregar tu apellido" });
      return false;
    }
    if (!order.address.phone) {
      setError({ message: "Favor de Agregar tu número de contacto" });
      return false;
    }
    if (!order.address.address) {
      setError({ message: "Favor de Agregar tu dirección" });
      return false;
    }
    if (!order.address.zip) {
      setError({ message: "Favor de Agregar tu código postal" });
      return false;
    }
    if (!order.address.city) {
      setError({ message: "Favor de Agregar tu ciudad" });
      return false;
    }
    if (!order.address.state || order.address.state === "false") {
      setError({ message: "Favor de Agregar tu estado" });
      return false;
    }
    setError(null);
    return true;
  };
  const next = (set_section?: SectionTypes) => {
    setError(null);
    const current_index = section_types.findIndex((s) => s === section);
    if (!sectionValidation()) {
      return;
    }
    if (set_section) {
      setSection(set_section);
    } else if (current_index >= 0) {
      setSection(section_types[current_index + 1]);
    }
  };

  const back = () => {
    const current_index = section_types.findIndex((s) => s === section);
    if (current_index >= 1) {
      setSection(section_types[current_index - 1]);
    }
    setSection(section_types[0]);
  };

  const handleDelivery = (ev: { target: HTMLInputElement }) => {
    const value = ev.target.value as DeliveryTypes;
    if (!value) return false;
    const set_order = { ...order };
    set_order.delivery_type = value;
    setOrder(set_order);
  };

  const handleAddresType = (ev: { target: HTMLInputElement }) => {
    const value = ev.target.value;
    const type = ev.target.id as keyof AddressType;
    if (!type || typeof type === "undefined") return false;
    const set_order = { ...order };
    if (!set_order.address || typeof set_order.address === "undefined") {
      set_order.address = {} as AddressType;
    }
    //@ts-ignore next-line
    set_order.address[type] = value;
    setOrder(set_order);
  };

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const set_order = { ...order };
    const payment_type = e.target.value as PaymentTypes;
    set_order.payment_type = payment_type;
    setOrder(set_order);
  };

  const promtMakeOrder = () => {
    if (!order.payment_type) {
      setError({ message: "Favor de seleccionar un método de pago" });
      return;
    }
    makeOrder();
  };
  const makeOrder = () => {
    if (!user) {
      setError({ message: "Favor de iniciar sesión para continuar" });
      return;
    }
    if (!cart_products) {
      setError({
        message: "Favor de agregar productos al carrito para continuar",
      });
      return;
    }
    setLoading(true);
    const order_products = cart_products.map((p) => ({
      id: p.id,
      idpart: p.idpart,
      iva: p.iva || false,
      price: p.price,
      title: p.descp1,
      fconvs: p.fconvs,
      price_num: p.price_num,
      qty: p.qty ? (p.fconvs ? p.qty * p.fconvs : p.qty) : 0,
      image: p.images[0] || "https://via.placeholder.com/200",
    }));
    ///convert order.totals to number type
    const order_totals = { ...order.totals };
    const order_data = {
      ...order,
      products: order_products,
      status: "pending",
      date: new Date(),
      totals: order_totals,
      user: {
        uid: user.uid,
        email: user.email,
        name: user.displayName,
        phone: user.phoneNumber,
      },
    };
    console.log("index.tsx:259 | order_data", order_data);
    db.collection("orders")
      .doc(user.uid)
      .collection("orders")
      .add(order_data)
      .then((docRef) => {
        console.log("Order created with ID: ", docRef.id);
        setTimeout(() => {
          setLoading(false);
          next("thankyou");
        }, 1500);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  };

  const setSelectedAddress = (set_section: AddressType | false) => {
    const set_order = { ...order };
    if (!set_section) {
      delete set_order.address;
    } else {
      set_order.address = set_section;
    }
    setOrder(set_order);
  };

  const renderStep = () => {
    switch (section) {
      case "delivery":
        return (
          <DeliverySection
            next={next}
            delivery_type={order.delivery_type || false}
            handleDelivery={handleDelivery}
          />
        );
      case "address":
        return (
          <AddressSection
            setSelectedAddress={setSelectedAddress}
            user={user}
            handleSections={handleSections}
            handleType={handleAddresType}
            deliveryData={order.address}
          />
        );
      case "branch-selection":
        return (
          <BranchSelection
            branches={branches}
            next={next}
            selectBranch={selectBranch}
            active_branch={order.selectedBranch?.id}
          />
        );
      case "payment":
        return (
          <PaymentSection
            handleSections={handleSections}
            handleRadioChange={handleRadioChange}
            payment_type={order.payment_type}
          />
        );
      case "resume":
        return (
          <ResumeSection
            makeOrder={promtMakeOrder}
            order={order}
            loading={loading}
          />
        );
      case "thankyou":
        return <ThankYouSection order={order} />;
    }
  };

  return (
    <div className="checkout">
      <Container>
        <div className="row justify-content-between">
          <div className="col-md-7">
            <CheckoutBreadcrumbs
              section={section}
              back={back}
              sectionChange={handleSections}
            />
            <div className="hide-on-desktop">
              <h3>Total del Order de Compra</h3>
              <div>${order?.totals ? order.totals.total : ""}</div>
            </div>{" "}
            {error ? (
              <div className="alert alert-danger my-4">{error.message}</div>
            ) : null}
            {renderStep()}
            {error ? (
              <div className="alert alert-danger my-4">{error.message}</div>
            ) : null}
          </div>
          <div className="col-md-4">
            {order?.totals && cart_products ? (
              <CartCheckout
                section={section}
                totals={order.totals}
                products={cart_products}
              />
            ) : null}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default CheckoutPage;
