import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { Product } from "../../models/product";
import { getPrice } from "../../middleware/iva";
import { UserType } from "../../models/user_model";
import { getProductUrl } from "../../middleware/common-functions";

const RelatedProducts = ({
  products,
  loading,
}: {
  products?: Product[];
  loading: boolean;
}) => {
  const user: UserType = JSON.parse(localStorage.getItem("user") || "{}");
  return (
    <section className="featured-products pb-5">
      <Row className="mb-3 text-left pb-3">
        <Col>
          <h4 className="sub-title">Productos Similares</h4>
        </Col>
      </Row>
      <Row>
        {loading && "Loading"}
        {products &&
          products.map((item, ind) => (
            <RelatedProduct
              product={item}
              key={`RelatedProduct_${item.id}_${ind}`}
              user={user}
            />
          ))}
      </Row>
    </section>
  );
};

export default RelatedProducts;

type RelatedProductProps = {
  product: Product;
  user: UserType;
};
const RelatedProduct: React.FC<RelatedProductProps> = ({ product, user }) => (
  <Col className="col-6 col-md-2 featured-product px-2">
    <div className="pb-3">
      <Link to={getProductUrl(product)}>
        {" "}
        <img
          src={
            product.images.length > 0
              ? product.images[0]
              : "https://via.placeholder.com/200"
          }
          alt="Mi cerveza"
        />
      </Link>
    </div>
    <h5>
      <Link to={getProductUrl(product)}>{product.descp1}</Link>
    </h5>
    <div className="clave">
      <Link to={getProductUrl(product)}> {product.idpart} </Link>
    </div>
    <div className="price">
      {product.prices ? `$${getPrice(product.prices, product.fconvs)}` : ""}
    </div>
    <div></div>
  </Col>
);
