import React from "react";
import { Row, Col } from "react-bootstrap";
const MiniCartItem = ({
  image,
  name,
  clave,
  price,
  qty,
}: {
  [key: string]: any;
}) => {
  return (
    <Row className="product-mini-cart align-items-center mt-4">
      <Col className="col-3">
        <img src={image} alt="Mi cerveza" />
      </Col>
      <Col className="col-9">
        <h5>{name}</h5>
        <div className="clave">{clave}</div>
      </Col>
      <Col className="col-6">Cantidad: {qty}</Col>
      <Col className="col-6">
        <div className="price text-right">{price}</div>
      </Col>
    </Row>
  );
};

export default MiniCartItem;
