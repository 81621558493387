import { Product } from "../models/product";
import { formatNumber } from "./common-functions";
import { getIva } from "./iva";

export type Totals = {
  total: string;
  subtotal: string;
  shipping?: string;
  tax?: string;
  iva?: string;
  discount?: string;
  comission?: string;
};

export type Promotion = {
  type: string;
  value: number;
};

export const getTotals = (
  products: (Product & { qty: number })[],
  promotion?: Promotion
): Totals => {
  if (products.length < 1) return { total: "0", subtotal: "0" };
  let discount = 0;
  let sum = products
    .map((item) => {
      return item.price_num! * item.qty;
    })
    .reduce((a, b) => a + b, 0);
  const iva_value = getIva();
  ///add tax only from products that have it
  let tax = products
    .filter((item) => item.iva)
    .map((item) => {
      return item.price_num! * item.qty;
    })
    .reduce((a, b) => a + b, 0);
  tax = tax * iva_value;
  if (promotion) {
    if (promotion.type === "total") {
      sum = sum - promotion.value;
      discount = promotion.value;
    } else if (promotion.type === "percentage_all") {
      const discount_promo = sum * promotion.value;
      sum = sum - discount_promo;
      discount = discount_promo;
    }
  }
  const subtotal = sum;
  const total = subtotal + tax;
  const response = {
    total: formatNumber(total),
    subtotal: formatNumber(subtotal),
    tax: formatNumber(tax),
    discount: formatNumber(discount),
  };
  return response;
};
