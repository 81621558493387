import React from "react";
import { Modal, Container, Row, Col } from "react-bootstrap";
import MiniCartItem from "../mini-cart-item";
import Button from "../buttons";

const MiniCart = ({
  show,
  onClick,
  image,
  name,
  clave,
  price,
  qty,
  totalqty,
  subtotal,
}: any) => {
  return (
    <>
      <Modal
        className="mini-cart-modal"
        backdrop={false}
        show={show}
        animation={false}
      >
        <Modal.Header>
          <div className="md">
            {" "}
            <i className="green l icon-check mr-2"></i>Producto Agregado
          </div>
          <Button
            onClick={onClick}
            children=""
            width="10px"
            icon="fas fa-times"
            title=""
            buttonclasses="mini-cart"
          />
        </Modal.Header>

        <Modal.Body className="mini-cart-modal">
          <Container>
            <MiniCartItem
              image={image}
              name={name}
              clave={clave}
              price={price}
              qty={qty}
            />
          </Container>
        </Modal.Body>

        <Modal.Footer>
          <Container>
            <Row>
              <Col className="col-6 bold">({totalqty} Productos)</Col>
              <Col className="col-6"></Col>
              <Col className="col-6 bold">Sub-Total:</Col>
              <Col className="col-6 text-right bold">${subtotal} MXN</Col>
            </Row>
            <div className="text-center mt-4 mb-2">
              <Button
                onClick={onClick}
                children="Proceder al Pago"
                width="60%"
                icon="icon-cart-plus mr-1 sm"
                title=""
                buttonclasses="primary"
              />
            </div>
          </Container>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MiniCart;
