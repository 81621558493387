import React from "react";
import firebase from "firebase/app";
import AddressPage from "./address_page";
import { SectionTypes } from "../../models/checkout";
import { AddressType } from "../../models/address";

type CheckoutPageProps = {
  handleSections: (set_section: SectionTypes) => void;
  setSelectedAddress: (set_section: AddressType | false) => void;
  handleType: any;
  user: firebase.User | null | undefined;
  deliveryData?: AddressType;
};

const AddressSection: React.FC<CheckoutPageProps> = ({
  handleSections,
  handleType,
  setSelectedAddress,
  deliveryData,
  user,
}) => {
  return (
    <div className="pr-0 pr-md-5">
      <AddressPage
        setSelectedAddress={setSelectedAddress}
        user={user}
        handleSections={handleSections}
        handleType={handleType}
        deliveryData={deliveryData}
      />
    </div>
  );
};

export default AddressSection;
