import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
const LinksData = [
  {
    name: "Ayuda",
    items: [
      {
        name: "Ayuda con tu pedido",
        link: "/",
      },
      {
        name: "Rastreo de pedidos",
        link: "/",
      },
      {
        name: "Políticas generales",
        link: "/",
      },
      {
        name: "Facturación",
        link: "/",
      },
    ],
  },
];

const FooterThankYou = () => {
  return (
    <div className="footer-thankyou pt-5">
      <Row>
        <Col className="text-center text-md-left col-12 col-md-3 pt-4 pt-md-3">
          <Link to="/" className="footer-title-link mb-4">
            Ir a mi perfil
          </Link>
        </Col>
        {LinksData.map((link, idx) => {
          return (
            <Col
              className="text-center text-md-left col-12  col-md-3 pt-4 pt-md-3"
              key={idx}
            >
              <div>
                {link.name && <h3 className="mb-4 title">{link.name}</h3>}
                <ul>
                  {link.items.map((item, idx) => {
                    return (
                      <li key={idx}>
                        <Link to={item.link}>{item.name}</Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </Col>
          );
        })}
        <Col className="text-center text-md-left col-12 col-md-3 pt-4 pt-md-3">
          <div>
            <h3 className="mb-4 title">Contáctanos</h3>
            <ul>
              <li>
                <strong>Baja California:</strong>
              </li>
              <li>+52 (664) 168 49 286</li>
              <li>ventas.tijuana@micervesa.mx</li>
            </ul>
            <ul>
              <li>
                <strong>Resto del país:</strong>
              </li>
              <li>+52 (614) 427 86 22</li>
              <li>+52 (614) 413 02 16</li>
              <li>ventas@micervesa.mx</li>
            </ul>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default FooterThankYou;
