import React from "react";
import { Row, Col, Form, Alert } from "react-bootstrap";
import Input from "../../components/input";
import Button from "../../components/buttons";
import { Link } from "react-router-dom";
import useUserAuth from "../../hooks/useUserAuth";
const SingUpForm = () => {
  const {
    email,
    setEmail,
    emailInvalid,
    emailErrMsg,
    password,
    setPassword,
    passwordInvalid,
    passwordErrMsg,
    fname,
    setFname,
    fnameInvalid,
    fnameErrMsg,
    lname,
    setLname,
    lnameInvalid,
    lnameErrMsg,
    signUp,
    alertMsg,
    clearAlertMsg,
  } = useUserAuth();
  const benefits = [
    "Verifica el estado de tus compras",
    "Recibe promociones muy atractivas",
    "Compra con menos clicks",
  ];
  return (
    <Col md={12}>
      <Row className="singup-form">
        <Col md={4}>
          <section className="px-3 mx-3">
            <div className="login-title pb-2">
              <h2>Registrarse</h2>
            </div>
            <div className="pb-3">
              <p>
                Para poder ingresar al portal online de Mi CerveSA debes tener
                una cuenta de cliente con nosotros
              </p>
            </div>
            <Form>
              <Row>
                <Input
                  id="fname"
                  label="Nombre"
                  className="col-md-6"
                  name="fname"
                  value={fname}
                  onChange={(e) => setFname(e.target.value)}
                  error={fnameInvalid ? fnameErrMsg : ""}
                />
                <Input
                  id="lname"
                  label="Apellido"
                  className="col-md-6"
                  name="lname"
                  value={lname}
                  onChange={(e) => setLname(e.target.value)}
                  error={lnameInvalid ? lnameErrMsg : ""}
                />
                <Input
                  id="email"
                  type="email"
                  label="Email"
                  className="col-md-12"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={emailInvalid ? emailErrMsg : ""}
                />
                <Input
                  id="password"
                  label="Password"
                  className="col-md-12"
                  type="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  error={passwordInvalid ? passwordErrMsg : ""}
                />

                <Col lg={12}>
                  <div className="">
                    <Button
                      onClick={signUp}
                      children="Registrarme"
                      width="200px"
                      icon=""
                      title=""
                      buttonclasses="secondary mt-3 text-white  ml-auto mr-auto"
                    />
                  </div>
                </Col>

                {alertMsg ? (
                  <Col lg={12}>
                    <Alert
                      width="200px"
                      style={{ marginTop: 20 }}
                      variant="danger"
                      onClose={clearAlertMsg}
                      dismissible
                    >
                      {alertMsg}
                    </Alert>
                  </Col>
                ) : (
                  ""
                )}

                <Col lg={12} className="mt-4">
                  <Link to="/login">¿Ya tienes Cuenta? Inicia Sesión</Link>
                </Col>
              </Row>
            </Form>
          </section>
        </Col>
        <Col md={4} className="border-left">
          <section className="px-3 mx-3 benefits">
            <h2>Que puedes hacer con tu cuenta</h2>
            <ul>
              {benefits.map((item, key) => (
                <li key={`benefits_${key}`}>
                  <i className="fas fa-check text-success mr-2"></i> {item}
                </li>
              ))}
            </ul>
          </section>
        </Col>
      </Row>
    </Col>
  );
};

export default SingUpForm;
