import React from "react";
import { Container, Row } from "react-bootstrap";
import LoginForm from "./login";
// import SingUpForm from "./sing-up";

const LoginPage = () => (
  <main className="login">
    <Container>
      <Row>
        <LoginForm />
        {/* <SingUpForm /> */}
      </Row>
    </Container>
  </main>
);

export default LoginPage;
