import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const Legals: React.FC = () => {
  return (
    <section className="legals py-3">
      <Container>
        <Row>
          {" "}
          <Col md={6}>
            <Row>
              <Col md={6}>
                <div className="xxs text-center text-md-left ">
                  <Link to="/">Términos y Condiciones</Link>
                </div>
              </Col>
              <Col md={6}>
                <div className="xxs text-center text-md-left py-2 py-md-0">
                  {" "}
                  <Link to="/">Aviso de privacidad</Link>
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            {" "}
            <div className="xxs text-center text-md-right ">
              {" "}
              <Link to={{
                pathname:"https://vamedia.co/"
              }
              } 
              target="_blank">VAMEDIA</Link>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Legals;
