import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const images_data = [
  {
    image: "/images/home/brand-1.png",
  },
  {
    image: "/images/home/brand-2.png",
  },
  {
    image: "/images/home/brand-3.png",
  },
  {
    image: "/images/home/brand-4.png",
  },
  {
    image: "/images/home/brand-5.png",
  },
  {
    image: "/images/home/brand-6.png",
  },
];
const Brands = () => {
  return (
    <section className="pt-5 pb-5">
      <Container className="mt-5">
        <Row className="mb-3 text-center">
          <Col>
            <h3>Las Mejores Maltas Importadas</h3>
          </Col>
        </Row>
        <Row className="align-items-center py-4">
          {images_data.map((item, ind) => (
            <Brand image={item.image} />
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Brands;

const Brand = ({ ind, image }: { [key: string]: any }) => (
  <Col md={2} key={ind} className="col-6 text-center brands-item">
    <img src={image} alt="Mi cerveza" />
  </Col>
);
