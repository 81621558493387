import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [

    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
      },
    },
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ],
};

const images_data = [
  {
    image: "/images/home/factory.jpg",
  },
  {
    image: "/images/home/factory.jpg",
  },
  {
    image: "/images/home/factory.jpg",
  },
  {
    image: "/images/home/factory.jpg",
  },
  {
    image: "/images/home/factory.jpg",
  },
];
const Knowus = () => {
  return (
    <Container >
      <div className="knowus mt-5">
        <Row className="align-items-center">
          <Col md={6}>
            <Slider {...settings} className="pb-3">
              {images_data.map((item, ind) => (
                <SlideKnowus image={item.image} />
              ))}
            </Slider>
          </Col>
          <Col md={6} >

            <div className="px-4 px-lg-5">
              <h4>CONOCENOS MEJOR</h4>
              <p>
                Maltas e Insumos Cerveceros SA de CV nace al ver la necesidad de
                pequeños cerveceros de conseguir ingredientes a nivel nacional y
                con el objetivo de ayudar a desarrollar la industria de la cerveza
                artesanal en México
            </p>

              <div>
                <Link to="/">+ Nuestra Misión y Visión</Link>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Knowus;

const SlideKnowus = ({ ind, image }: { [key: string]: any }) => (
  <div key={ind}>
    <img src={image} alt="Mi cerveza" />
  </div>
);
