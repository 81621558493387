import { useState } from "react";
import { Link } from "react-router-dom";
const LinksData = [
  {
    name: "Productos",
    url: "/",
    nombre: "Malta", 
    path: "/malta",
    sub_items: [{nombre: "Extractos", url:"/extractos"}, {nombre:"Lupulos", url: "/lupulos"}]
  },
  {
    name: "Servicios",
    url: "/servicios",
    nombre: "RastreoPedidos",
    path: "/rastreo"
  },
  {
    name: "Contacto",
    url: "/contacto",
    nombre: "Ayuda con tu pedido",
    path: "/ayuda"
  },
];  
const MobileNav = () => {
  const [show_nav, setShow] = useState(false);

  const toggleMob = () => {
    const toggle_mob = !show_nav;
    setShow(toggle_mob);
  };
  return (
    <>
      <div className="d-block d-md-none" id="mainMobileNav">
        <div className={`${show_nav ? "d-block" : "d-block"}`}>
          <div className="header-mob">
            <div className="d-flex">
              <button className="nav-burger mr-3" onClick={toggleMob}>
                <i className="icon-menu white xl"></i>
              </button>
              <div className="logo_mob">
                <Link to="/">
                  <img src="images/nav/logo-mob.png " alt="MiCerveza"></img>
                </Link>
              </div>
            </div>
            <div>
              <button className="nav-burger mr-3">
                <i className="icon-search white l"></i>
              </button>
              <Link to="/carrito">
                <i className="icon-shopping-cart white l"></i>
              </Link>
            </div>
          </div>
        </div>
        <div className={`mob-links ${show_nav ? "active" : "hidden"}`}>
          <div className="d-flex justify-content-between align-items-center bg-white" id="linksActiveHeader">
            <div className="w-100 d-flex justify-content-start align-items-center">
              <i className="icon-user-circle pr-2"></i>
              <div>
                <div id="accountUserName">Jose Ruiz</div>
                <div>Mi Cuenta</div>
              </div>
            </div>
            <button onClick={toggleMob}>
              <i className="icon-close"></i>
            </button>
          </div>
          {LinksData.map((link, idx) => {
            return (
              <div className="nav-item-cont" key={idx}>
                <ul>
                  <li>
                    <Link to={link.url} className="mb-3 mobile-nav-a">
                      {link.name}
                    </Link>
                  </li>
                </ul>

                <div id="subItemsNavMob">
                  <ul>
                    <li>
                    <Link to={link.path} className="sub-item">
                        {link.nombre}
                      </Link>
                    </li>
                   
                  </ul>
                </div>

              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
export default MobileNav;
