import React from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getPrice } from "../middleware/iva";
import { formatNumber, getProductUrl } from "../middleware/common-functions";

import { Product } from "../models/product";
import { UserType } from "../models/user_model";

type ProductProps = {
  product: Product;
  user: UserType;
};
const ProductBlock: React.FC<ProductProps> = ({ product, user }) => {
  const image = product.images ? product.images[0] : null;
  const name = product.descp1;
  const description = product.brand;
  const clave = `Código: ${product.idpart}`;
  const link = getProductUrl(product);
  const { price, regular_price } = product.prices
    ? getPrice(product.prices, product.fconvs)
    : { price: null, regular_price: undefined };
  if (price === null) {
    return null;
  }
  return (
    <Col className="col-6 col-md-3">
      <div className="product pt-5">
        <Link to={link}>
          <div className="image">
            <img
              src={image || "https://via.placeholder.com/200"}
              alt={`Foto de ${name}`}
            />
          </div>
          <h5>{name}</h5>
          <p>{description}</p>
          <div className="clave">{clave}</div>
          {user && user.id ? (
            <>
              {" "}
              <div className="price">
                {price && price !== "NOPRICE" ? (
                  `$${formatNumber(price)}`
                ) : (
                  <small>No tienes acceso a este producto</small>
                )}
              </div>
              {regular_price ? (
                <span className="regular_price"> ${regular_price}</span>
              ) : null}
            </>
          ) : null}
        </Link>
      </div>
    </Col>
  );
};

export default ProductBlock;
