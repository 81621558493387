import React from "react";

interface ButtonProps {
  children?: React.ReactNode;
  onClick?: () => void;
  width?: string;
  icon?: string;
  title?: string;
  buttonclasses?: string;
  disabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  width,
  icon,
  title,
  buttonclasses,
  disabled,
}) => {
  return (
    <button
      onClick={onClick}
      title={title}
      disabled={disabled}
      type="button"
      className={`${buttonclasses} ${disabled ? "disabled opacity_7" : ""}`}
      style={{
        width,
      }}
    >
      <i className={icon}></i> {children}
    </button>
  );
};

type IconButtonProps = {
  icon: string;
  value: string;
  help?: string;
  onClick?: () => void;
  title?: string;
  classes?: string;
  disabled?: boolean;
  active?: boolean;
  hover?: "primary" | "secondary" | "success" | "danger" | "warning" | "info";
};
export const IconButton: React.FC<IconButtonProps> = ({
  icon,
  onClick,
  title,
  classes,
  disabled,
  value,
  active,
  help,
  hover,
}) => (
  <div
    className={`icon_button ${disabled ? "disabled" : ""} ${classes || ""} ${
      hover ? `hover_${hover}` : ""
    } ${active ? "active" : ""}`}
    data-value={value}
    onClick={onClick}
  >
    <i className={icon}></i>
    <div>
      <span>{title}</span>
      {help && <small className="help">{help}</small>}
    </div>
  </div>
);

export default Button;
