import React from "react";
import PaymentMethod from "../../../components/payment-method";
import { Row } from "react-bootstrap";
import { PaymentTypes } from "../../../models/checkout";

type PaymentMethodsProps = {
  payment: PaymentTypes;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};
const PaymentMethods: React.FC<PaymentMethodsProps> = ({
  payment,
  onChange,
}) => {
  return (
    <div className="payment-methods">
      <Row className="mt-4">
        <PaymentMethod
          id="b"
          name="payment"
          icon="credit-card"
          label="Pagar Online"
          value="online"
          onChange={onChange}
          checked={payment === "online"}
        />
        <PaymentMethod
          id="c"
          name="payment"
          icon="dollar"
          label="Transferencia Bancaria"
          value="transfer"
          onChange={onChange}
          checked={payment === "transfer"}
        />
        <PaymentMethod
          id="b"
          name="payment"
          icon="calculator"
          label="Pago a Credito"
          value="credit"
          onChange={onChange}
          checked={payment === "credit"}
        />
      </Row>
    </div>
  );
};

export default PaymentMethods;
