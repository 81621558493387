import useUserAuth from "../../hooks/useUserAuth";
import { fauth } from "../../middleware/firebase";

const userProperties = [
  "fname",
  "lname",
  "email",
  "dcalle",
  "dcidad",
  "dcolon",
  "dcpost",
  "ddeleg",
  "destdo",
  "dipais",
  "numext",
  "numint",
];

const editableUserProperties: string[] = [];

const labelsForUserProperties: { [key: string]: string } = {
  fname: "Nombre",
  lname: "Apellido",
  email: "Correo",
  dcalle: "Calle",
  dcidad: "Ciudad",
  dcolon: "Colonia",
  dcpost: "Codigo Postal",
  ddeleg: "Delegacion",
  destdo: "Estado",
  dipais: "Pais",
  numext: "Numero Exterior",
  numint: "Numero Interior",
};

const Account = () => {
  const { user } = useUserAuth();
  console.log(user);
  const logOutHandler = async () => {
    await fauth.signOut();
    //// delete all localStorage data
    localStorage.clear();
  };

  return (
    <div className="my_account">
      <div className="card  material-shadows mb-3">
        <div className="card-body">
          {userProperties.map((key) => {
            const item = user[key];
            return (
              <div className="form-group">
                <label htmlFor={`input_${key}`}>
                  {labelsForUserProperties[key]}{" "}
                </label>
                <input
                  disabled={!editableUserProperties.includes(key)}
                  type="text"
                  className="form-control form-control-plaintext"
                  defaultValue={item}
                  id={`input_${key}`}
                  aria-describedby="emailHelp"
                />
              </div>
            );
          })}
        </div>
      </div>
      <button
        className="btn btn-danger float-right mb-3"
        onClick={logOutHandler}
      >
        Cerrar Sesión
      </button>
    </div>
  );
};

export default Account;
