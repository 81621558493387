import React, { useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import Input from "../../components/input";
import Button from "../../components/buttons";
const ContactForm = () => {
  const [first_name, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const errorMSG = "Llena este campo.";
  const [validated, setValidated] = useState(false);
  const [message, setMessage] = useState("");

  const handleComplete = () => {
    if (
      first_name.trim() === "" ||
      email.trim() === "" ||
      message.trim() === ""
    ) {
      setValidated(true);
    }
  };
  return (
    <section className="contact-client">
      <Container>
        <Row className="mt-5 pt-5">
          <Col>¡Contáctanos, será un gusto atenderte!</Col>
        </Row>
        <Row className="align-items-center mt-2">
          <Col md={6}>
            <Form>
              <Row>
                <Input
                  id="firstName"
                  label="First Name"
                  className="col-md-6"
                  type="text"
                  name="firstName"
                  value={first_name}
                  onChange={(e) => setFirstName(e.target.value)}
                  error={validated && first_name === "" ? errorMSG : ""}
                />

                <Input
                  id="email"
                  type="email"
                  label="Email"
                  className="col-md-6"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={validated && email === "" ? errorMSG : ""}
                />

                <Input
                  id="message"
                  className="col-md-12"
                  rows={4}
                  label="Message"
                  name="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Your Message*"
                  error={validated && message === "" ? errorMSG : ""}
                />
                <Col lg={12}>
                  <Button
                    onClick={handleComplete}
                    children="Enviar"
                    width="100px"
                    icon=""
                    title=""
                    buttonclasses="secondary mt-3"
                  />
                </Col>
              </Row>
            </Form>
          </Col>
          <Col md={6}>
            <div className="px-0 px-md-5 py-5 py-md-0">
              <p>
                {" "}
                MICerveSA se especializa en la venta al mayoreo de insumos para
                cerveza a microcervecerías y tiendas de homebrewing.
              </p>
              <p>
                {" "}
                Las ventas a menudeo se realizan a través de la tienda en línea
                www.fermentando.com.mx
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ContactForm;
