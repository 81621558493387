import React from "react";

const PaymentForm: React.FC = () => (
  <div className="credit-card">
    <small>
      <i className="fa fa-info-circle"></i> Una vez que haya realizado el equipo
      de Mi CerveSa® lo revisará y se pondrá en contacto con usted para
      confirmar su orden y realizar el pago.
    </small>
  </div>
);

export default PaymentForm;
