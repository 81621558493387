import React from "react";
import { paymentTxt } from "../../../middleware/common-functions";
import { PaymentTypes } from "../../../models/checkout";

type PaymentInfoProps = {
  payment_type: PaymentTypes | false;
};
const PaymentInfo: React.FC<PaymentInfoProps> = ({ payment_type }) => (
  <div className="payment-info pb-3 pt-3">
    <h4>Forma de Pago</h4>
    <p> {paymentTxt(payment_type)} </p>
  </div>
);

export default PaymentInfo;
