import React from "react";
import { Col, Row } from "react-bootstrap";
const bank_info = [
  {
    bank: "Banorte",
    reference: "123456789",
    num: "123456789",
  },
  {
    bank: "Santander",
    reference: "123456789",
    num: "123456789",
  },
  {
    bank: "BBVA",
    reference: "123456789",
    num: "123456789",
  },
];
const BankData = () => {
  return (
    <div className="bank-data pb-5 pb-5">
      <Row>
        <Col md={12}>
          <div className="lg pb-2">Datos para Transferencia Bancaria</div>
        </Col>
      </Row>
      <Row>
        {bank_info.map((item, ind) => (
          <BankItem
            bank={item.bank}
            reference={item.reference}
            num={item.num}
          />
        ))}
      </Row>
    </div>
  );
};

export default BankData;

const BankItem = ({ bank, reference, num, ind }: { [key: string]: any }) => (
  <Col className="col-12 col-sm-6 col-md-4" key={ind}>
    <div className="bank-title lg-bold">{bank}</div>
    <div>Referencia</div>
    <div className="md">{reference}</div>
    <div>Numero de Cuenta</div>
    <div className="md">{num}</div>
  </Col>
);
