import React, { useState } from "react";
import { toast } from "react-toastify";

import Button from "../../components/buttons";
import { db, fauth } from "../../middleware/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { Product } from "../../models/product";
import MiniCart from "../../components/Modals/mini-cart";

const products_data = [
  {
    image: "/images/home/product-1-c.jpg",
    name: "Fermentis - SafBrew DA16 (500 grs)",
    clave: "Clave: LC22 ",
    price: "$2,481.40 MXN",
    qty: "3",
  },
];

const totalqty = "3";
const subtotal = "84343";

type AddToCartUIProps = {
  product: Product;
};
const AddToCartUI: React.FC<AddToCartUIProps> = ({ product }) => {
  const [show, setShow] = useState(false);
  const [product_qty, setProductQty] = useState(1);

  const [user] = useAuthState(fauth);
  const handClose = () => setShow(false);
  const AddToCart = async () => {
    if (user && product.id) {
      await db
        .collection("cart")
        .doc(user.uid)
        .collection("products")
        .doc(product.id)
        .set({ id: product.id, qty: product_qty, fconvs: product.fconvs });
      setShow(true);
      setTimeout(() => setShow(false), 3000);
    } else if (!user) {
      toast(
        "Debes iniciar sesión para esta operación. Serás redirigido a la pagina de inicio de sesión."
      );
      window.location.href =
        "/signin?to=" + encodeURIComponent(window.location.href);
    } else {
      toast("Lo sentimos, no es posible realizar esta operación.");
    }
  };

  return (
    <div>
      {products_data.map((product, ind) => (
        <MiniCart
          show={show}
          onClick={handClose}
          image={product.image}
          name={product.name}
          clave={product.clave}
          price={product.price}
          qty={product.qty}
          totalqty={totalqty}
          subtotal={subtotal}
        />
      ))}
      <div className="sm-bold d-block pt-3">Cantidad:</div>
      <div className="product-quantity py-3">
        <input
          className="form-control"
          type="number"
          min="1"
          max="100"
          onChange={(e) => setProductQty(parseInt(e.target.value))}
          value={product_qty}
        />
        <Button
          onClick={AddToCart}
          children="Agregar"
          width="50%"
          icon="icon-cart-plus mr-1"
          title=""
          buttonclasses="btn btn-add-to-cart"
        />
      </div>
    </div>
  );
};

export default AddToCartUI;
