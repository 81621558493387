import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import Loader from "../../components/utils/loader";
import Address from "./address";
import EmptyJumbotron from "../../components/empty-jumbotron";
import useUserCollection from "../../hooks/useUserCollection";
import { useHistory } from "react-router";
import { AddressType } from "../../models/address";
import { useParams } from "react-router-dom";

const Addresses: React.FC = () => {
  const { data: addresses, loading } = useUserCollection<AddressType[]>({
    collection: "addresses",
    subcollection: "address",
    showNotification: true,
  });

  const [selectedAddress, setSelectedAddress] = useState<AddressType | null>(
    null
  );
  const history = useHistory();
  const { item_id } = useParams<{
    item_id: string;
  }>();

  useEffect(() => {
    if (!item_id) {
      setSelectedAddress(null);
    } else if (addresses) {
      const selected_oder = addresses.find((o) => o.id === item_id);
      if (!selected_oder) {
        return;
      }
      setSelectedAddress(selected_oder);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item_id, addresses]);

  const selectAddress = (ev: any) => {
    const address_id = ev.currentTarget.dataset.address_id;
    const selected_oder = addresses.find((o) => o.id === address_id);
    if (!selected_oder) {
      return;
    }
    history.push(`/cuenta/pedidos/${address_id}`);
    setSelectedAddress(selected_oder);
  };

  return (
    <div className="my_addresses">
      <div className="card  material-shadows mb-3">
        <div className="card-body">
          {loading ? (
            <Loader />
          ) : addresses ? (
            selectedAddress ? (
              <Address
                address={selectedAddress}
                backUrl={"/cuenta/direcciones"}
              />
            ) : (
              <AddressesTable data={addresses} clickFun={selectAddress} />
            )
          ) : (
            <EmptyJumbotron
              icon="fas fa-truck-loading"
              heading="Sin pedidos"
              text="No has hecho ningún pedido con nosotros"
            />
          )}
        </div>
      </div>
    </div>
  );
};

const AddressesTable = ({
  data,
  clickFun,
}: {
  data: AddressType[];
  clickFun: any;
}) => {
  if (isMobile) {
    return (
      <div className="list-group">
        {data.map((item) => {
          return (
            <button
              onClick={clickFun}
              className="list-group-item list-group-item-action hover"
              key={`mob_${item.id}`}
              data-address_id={item.id}
            >
              <div className="d-flex w-100 justify-content-between">
                <h5 className="mb-1">${item.address_name}</h5>
              </div>
              <p className="mb-1">{item.city}</p>
              <small>
                {item.state} - {item.zip}
              </small>
            </button>
          );
        })}
      </div>
    );
  }
  return (
    <table className="table table-striped table-hover hover xs_hidden">
      <thead>
        <tr>
          <th scope="col">Nombre</th>
          <th scope="col">Dirección</th>
          <th scope="col">CP</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => {
          return (
            <tr
              key={`table_${item.id}`}
              onClick={clickFun}
              data-address_id={item.id}
              className="hover_"
            >
              <th scope="row">{item.address_name}</th>
              <td>
                {item.address} {item.city}, {item.state}
              </td>
              <td>{item.zip}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Addresses;
