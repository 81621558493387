import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import moment from "../../middleware/moment";
import { statusTxt } from "../../middleware/common-functions";
import Loader from "../../components/utils/loader";
import Order from "./order";
import EmptyJumbotron from "../../components/empty-jumbotron";
import useUserCollection from "../../hooks/useUserCollection";
import { useHistory } from "react-router";
import { OrderDbType } from "../../models/order_model";
import { useParams } from "react-router-dom";

type OrdersProps = {
  type?: "orders" | "quotes" | "cart" | "checkout" | "order";
};

const Orders: React.FC<OrdersProps> = ({ type = "orders" }) => {
  const { data: orders, loading: orders_loading } = useUserCollection<
    OrderDbType[]
  >({
    collection: "orders",
    showNotification: true,
  });

  const [selectedOrder, setSelectedOrder] = useState<OrderDbType | null>(null);
  const history = useHistory();
  const { item_id } = useParams<{
    item_id: string;
  }>();

  useEffect(() => {
    if (!item_id) {
      setSelectedOrder(null);
    } else if (orders) {
      const selected_oder = orders.find((o) => o.api_id === item_id);
      if (!selected_oder) {
        return;
      }
      setSelectedOrder(selected_oder);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item_id, orders]);

  const selectOrder = (ev: any) => {
    const order_id = ev.currentTarget.dataset.order_id;
    const selected_oder = orders.find((o) => o.api_id === order_id);
    if (!selected_oder) {
      return;
    }
    history.push(`/cuenta/pedidos/${order_id}`);
    setSelectedOrder(selected_oder);
  };

  return (
    <div className="my_orders">
      <div className="card  material-shadows mb-3">
        <div className="card-body">
          {orders_loading ? (
            <Loader />
          ) : orders ? (
            selectedOrder ? (
              <Order
                order_data={selectedOrder}
                type={type}
                backUrl={"/cuenta/pedidos"}
              />
            ) : (
              <OrdersTable data={orders} clickFun={selectOrder} />
            )
          ) : (
            <EmptyJumbotron
              icon="fas fa-truck-loading"
              heading="Sin pedidos"
              text="No has hecho ningún pedido con nosotros"
            />
          )}
        </div>
      </div>
    </div>
  );
};

const OrdersTable = ({
  data,
  clickFun,
}: {
  data: OrderDbType[];
  clickFun: any;
}) => {
  if (isMobile) {
    return (
      <div className="list-group">
        {data
          .sort((a, b) => (a.timestamp < b.timestamp ? -1 : 1))
          .map((item, key) => {
            return (
              <button
                onClick={clickFun}
                className="list-group-item list-group-item-action hover"
                key={`mob_${item.api_id}`}
                data-order_id={item.api_id}
              >
                <div className="d-flex w-100 justify-content-between">
                  <h5 className="mb-1">${item.totals.total}</h5>
                  <small>#{item.api_id}</small>
                </div>
                <p className="mb-1">{item.products_data.desc}</p>
                <small>
                  {statusTxt(item.status)} |{" "}
                  {moment(item.timestamp.toDate()).format("MMM DD ")}
                </small>
              </button>
            );
          })}
      </div>
    );
  }
  return (
    <table className="table table-striped table-hover hover xs_hidden">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Productos</th>
          <th scope="col">Total</th>
          <th scope="col">Estatus</th>
          <th scope="col">Fecha</th>
        </tr>
      </thead>
      <tbody>
        {data
          .sort((a, b) => (a.date > b.date ? -1 : 1))
          .map((item, key) => {
            return (
              <tr
                key={`table_${item.api_id}`}
                onClick={clickFun}
                data-order_id={item.api_id}
                className="hover_"
              >
                <th scope="row">{item.api_id ? `#${item.api_id}` : "-"}</th>
                <td>{item.products.map((p: any) => p.idpart).join("-")}</td>
                <td>${item.totals.total}</td>
                <td>{item.status}</td>
                <td>
                  {moment(item.date.toDate()).format("HH:MM - DD MMMM, YY ")}
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

export default Orders;
