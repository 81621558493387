import React from "react";
import { Row, Col, Form, Alert } from "react-bootstrap";
import Input from "../../components/input";
import Button from "../../components/buttons";
import { Link } from "react-router-dom";
import useUserAuth from "../../hooks/useUserAuth";
const benefits = [
  "Verifica el estado de tus compras",
  "Recibe promociones muy atractivas",
  "Compra con menos clicks",
];
const LoginForm = () => {
  const {
    email,
    setEmail,
    emailInvalid,
    emailErrMsg,
    password,
    setPassword,
    passwordInvalid,
    passwordErrMsg,
    login,
    alertMsg,
    clearAlertMsg,
  } = useUserAuth();

  return (
    <Col md={12}>
      <div className="container last-container ">
        <div className="row justify-content-between my-5 ">
          <div className="p-4 col-md-5">
            <h2 className="underlined my-4 py-2">Login</h2>
            <div className="form-group">
              <Form>
                <Row>
                  <Input
                    id="email"
                    type="email"
                    label="Email"
                    className="col-md-12"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={emailInvalid ? emailErrMsg : ""}
                  />
                  <Input
                    id="password"
                    label="Password"
                    className="col-md-12"
                    type="password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    error={passwordInvalid ? passwordErrMsg : ""}
                  />

                  <Col lg={12}>
                    <div className="">
                      <Button
                        onClick={login}
                        children="Iniciar Sesión"
                        width="200px"
                        icon=""
                        title=""
                        buttonclasses="secondary mt-3 text-white"
                      />
                    </div>
                  </Col>

                  {alertMsg ? (
                    <Col lg={12}>
                      <Alert
                        width="200px"
                        style={{ marginTop: 20 }}
                        variant="danger"
                        onClose={clearAlertMsg}
                        dismissible
                      >
                        {alertMsg}
                      </Alert>
                    </Col>
                  ) : (
                    ""
                  )}

                  <Col lg={12} className="mt-4">
                    <small>
                      Tienes problemas para entrar a tu cuenta?
                      <br />
                      <Link to="/recuperar">Recupera tu Password</Link>
                    </small>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
          <div className="p-4 col-md-5 border-left mt-4 d-flex flex-column justify-content-center">
            <h4>¿No tienes cuenta?</h4>
            <p>
              Crea tu cuenta gratis y disfruta de los beneficios de comprar
              online en MiCerveSA
            </p>
            <Link
              to="/sing-up"
              className="btn btn-outline-secondary btn-block text-center"
            >
              Crear cuenta
            </Link>
            <ul className="mt-4">
              {benefits.map((item, key) => (
                <li key={`benefits_${key}`}>
                  <i className="fas fa-check text-success mr-2"></i> {item}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default LoginForm;
