import React from "react";
import BankData from "./bank-data";
import OrderInfo from "./order-info";
import FooterThankYou from "./footer";
import { OrderType } from "../../../models/checkout";

type ThankyouProps = {
  order: OrderType;
};
const ThankYouPage: React.FC<ThankyouProps> = ({ order }) => {
  return (
    <div className="thank-you-page">
      <OrderInfo order={order} />
      {order.payment_type === "transfer" ? <BankData /> : null}
      <FooterThankYou />
    </div>
  );
};

export default ThankYouPage;
