import React from "react";
import { Link } from "react-router-dom";

type ProductsNavProps = {
  active: boolean;
  toggleNav: () => void;
};
const ProductsNav: React.FC<ProductsNavProps> = ({ active, toggleNav }) => {
  const linksData = getLinksData();
  return (
    <div className="nav ">
      <ul className="mainNav">
        {linksData.map((link, ind) => (
          <li key={ind} className={link.className}>
            {link.name === "Productos" ? (
              <button className="btn " onClick={toggleNav}>
                {link.name}
              </button>
            ) : (
              <Link to={link.url} className="mb-3">
                {link.name}
              </Link>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

const getLinksData = () => {
  const links = [
    {
      name: "Inicio",
      url: "/",
      className: "bordered",
    },
    {
      name: "Productos",
      url: "/productos",
      className: "bordered",
    },
    {
      name: "Quiénes somos",
      url: "/quienes-somos",
      className: "bordered",
    },
    {
      name: "Como ser cliente",
      url: "/como-ser-cliente",
      className: "bordered",
    },
    {
      name: "Contáctanos",
      url: "/contacto",
      className: "bordered",
    },
  ];
  return links;
};

export default ProductsNav;
