import React, {
  useLayoutEffect,
  useState,
  useEffect,
  useCallback,
} from "react";
import { Container, Col, Row, FormControl } from "react-bootstrap";
import { useAuthState } from "react-firebase-hooks/auth";
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
import { Link, useHistory } from "react-router-dom";
import { db, fauth } from "../../middleware/firebase";
import Banner from "./banner";
import MainNav from "./mainNav";
import ProductsNav from "./productsNav";

const Header: React.FC = () => {
  const [user] = useAuthState(fauth);
  const history = useHistory();
  const [searchString, setSearchString] = useState("");
  const [delayedSearchString, setDelayedSearchString] = useState("");
  const [productActive, productSetActive] = useState(false);
  const [searchResults] = useCollectionDataOnce<any>(
    db
      .collection("products")
      .where(
        "keywords",
        "array-contains-any",
        delayedSearchString.replace(/[^a-z0-9\s]/gi, "").split(" ")
      )
      .limit(20)
  );
  useLayoutEffect(() => {
    if (searchString.length >= 3) {
      const timer = setTimeout(function () {
        setDelayedSearchString(searchString.toLocaleLowerCase());
      }, 600);
      return () => clearTimeout(timer);
    }
  }, [searchString]);

  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  const handleScroll = useCallback(() => {
    // find current scroll position
    const currentScrollPos = window.pageYOffset;

    // set state based on location info (explained in more detail below)
    setVisible(
      (prevScrollPos > currentScrollPos &&
        prevScrollPos - currentScrollPos > 0) ||
        currentScrollPos < 200
    );

    // set state to new scroll position
    setPrevScrollPos(currentScrollPos);
  }, [prevScrollPos]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos, visible, handleScroll]);

  const goToProduct = (ev: { currentTarget: HTMLButtonElement }) => {
    const product_id = ev.currentTarget.dataset.id;
    if (!product_id) return;
    setSearchString("");
    history.push({ pathname: `/producto/${product_id}` });
  };

  const toggleProducts = () => {
    const toggle = !productActive;
    productSetActive(toggle);
  };

  return (
    <nav
      style={{ top: visible ? "0" : "-180px" }}
      className="header pb-2 mb-4 d-none d-md-block"
    >
      <Banner />
      <Container>
        <Row className="align-items-center  pt-2">
          <Col>
            <div className="logo">
              <Link to="/">
                <img src="/images/nav/logo-mi-cerveza.png" alt="MicerveSA" />
              </Link>
            </div>
          </Col>
          <Col md={10}>
            <Row>
              <Col md={12}>
                <Row className="align-items-center">
                  <Col md={7} className="p-0">
                    <FormControl
                      placeholder="Busca productos"
                      onChange={(e) => setSearchString(e.target.value)}
                      value={searchString}
                    />
                    <div
                      className={`search_results ${
                        searchResults && searchString ? "active" : ""
                      }`}
                    >
                      {delayedSearchString &&
                      searchResults &&
                      searchResults.length ? (
                        searchResults.map((x) => (
                          <button
                            data-id={x.id}
                            onClick={goToProduct}
                            className="btn search_item"
                          >
                            <div className="img_cont">
                              <img
                                src={
                                  x.images && x.images.length
                                    ? x.images[0]
                                    : "https://via.placeholder.com/200"
                                }
                                alt={x.descp1}
                              />
                            </div>{" "}
                            <div>
                              {x.descp1} <br />
                              <small>{x.idpart}</small>
                            </div>
                          </button>
                        ))
                      ) : delayedSearchString ? (
                        <span className="search_item ml-3">Sin Resultados</span>
                      ) : undefined}
                    </div>
                  </Col>
                  <Col md={2}>
                    <i className="icon-wheat"></i> Mis Favoritos
                  </Col>
                  <Col md={2}>
                    {user ? (
                      <Link to="/cuenta">
                        <i className="icon-user-circle-o"></i>{" "}
                        {user.displayName}
                      </Link>
                    ) : (
                      <Link to="/login">
                        {" "}
                        <i className="icon-user-circle-o"></i>Iniciar Sesión
                      </Link>
                    )}
                  </Col>
                  <Col md={1}>
                    {" "}
                    <Link to="/carrito">
                      {" "}
                      <i className="icon-shopping-cart">
                        <span> 0</span>
                      </i>
                    </Link>
                  </Col>
                </Row>
              </Col>
              <Col md={12} className="main_nav">
                <MainNav active={visible} toggleNav={toggleProducts} />
              </Col>
            </Row>
          </Col>
        </Row>
        <ProductsNav active={productActive} toggleNav={toggleProducts} />
      </Container>
    </nav>
  );
};

export default Header;
