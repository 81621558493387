import React from "react";
import { Col } from "react-bootstrap";

const PaymentMethod = ({
  icon,
  label,
  name,
  id,
  value,
  onChange,
  checked,
}: {
  [key: string]: any;
}) => {
  return (
    <Col sm={6}>
      <label>
        <input
          name={name}
          type="radio"
          id={id}
          value={value}
          onChange={onChange}
          checked={checked}
        />
        <div className="payment-method">
          <i className={`icon-${icon}`}></i>
          <div> {label}</div>
        </div>
      </label>
    </Col>
  );
};

export default PaymentMethod;
