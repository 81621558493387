import { useAsync } from "react-async-hook";
import firebase from "firebase/app";
import { db } from "../middleware/firebase";

export type Filters = {
  family: FilterItem;
  type: FilterItem;
  brands: FilterItem;
  size: FilterItem;
  origin: FilterItem;
};
export type FiltersBrands = {
  family: FilterItem;
  type: FilterItem;
  size: FilterItem;
  origin: FilterItem;
};

export type FilterItem = {
  title: string;
  value?: string;
  type: "checkbox";
  elements: string[];
  selected: string[];
};
interface BrandData {
  brand: string;
  family: string[];
  type: string[];
  products: string[];
  size: string[];
  pais: string[];
}
export function getFilterData(
  families: string[] = [],
  types: string[] = [],
  brands: string[] = [],
  sizes: string[] = [],
  others: string[] = [],
  prices: string[] = []
): Filters {
  return {
    family: {
      title: "Categoría",
      type: "checkbox",
      elements: Array.from(new Set(families)).filter(Boolean).sort() || [],
      selected: [],
    },
    type: {
      title: "Tipo",
      type: "checkbox",
      elements: Array.from(new Set(types)).filter(Boolean).sort() || [],
      selected: [],
    },
    brands: {
      title: "Marca",
      type: "checkbox",
      elements: Array.from(new Set(brands)).filter(Boolean).sort() || [],
      selected: [],
    },
    size: {
      title: "Tamaño",
      type: "checkbox",
      elements: Array.from(new Set(sizes)).filter(Boolean).sort() || [],
      selected: [],
    },
    origin: {
      title: "Páis de Origen",
      type: "checkbox",
      elements: Array.from(new Set(others)).filter(Boolean).sort() || [],
      selected: [],
    },
  };
}

export function getFilterDataBrands(
  families: string[] = [],
  types: string[] = [],
  sizes: string[] = [],
  others: string[] = [],
  prices: string[] = []
): FiltersBrands {
  return {
    family: {
      title: "Categoría",
      type: "checkbox",
      elements: Array.from(new Set(families)).filter(Boolean).sort() || [],
      selected: [],
    },
    type: {
      title: "Tipo",
      type: "checkbox",
      elements: Array.from(new Set(types)).filter(Boolean).sort() || [],
      selected: [],
    },
    size: {
      title: "Tamaño",
      type: "checkbox",
      elements: Array.from(new Set(sizes)).filter(Boolean).sort() || [],
      selected: [],
    },
    origin: {
      title: "Páis de Origen",
      type: "checkbox",
      elements: Array.from(new Set(others)).filter(Boolean).sort() || [],
      selected: [],
    },
  };
}

export async function getFilters(
  urlTypes: string[],
  query?: boolean
): Promise<Filters> {
  let productTags = db.collection(
    "product-tags"
  ) as any as firebase.firestore.Query<{
    families: string[];
    types: string[];
    brands: string[];
    sizes: string[];
    others: string[];
    prices: number[];
  }>;
  const [primaryType, secondaryType, tertiaryType] = urlTypes;
  if (primaryType)
    productTags = productTags.where("primaryType", "==", primaryType);
  if (secondaryType)
    productTags = productTags.where("secondaryType", "==", secondaryType);
  if (tertiaryType)
    productTags = productTags.where("tertiaryType", "==", tertiaryType);
  const aggFamilies: string[] = [];
  const aggTypes: string[] = [];
  const aggBrand: string[] = [];
  const aggSizes: string[] = [];
  const aggOthers: string[] = [];
  const aggPrices: number[] = [];
  if (!query) return getFilterData();

  try {
    // Retrieve product tags and iterate over each document
    const querySnapshot = await productTags.get();

    querySnapshot.forEach((doc) => {
      // Extract data from the document
      const data = doc.data();

      // Destructure the data object to get the filter categories
      const { families, types, brands, sizes, others, prices } = data;
      // Aggregate the data for each category by pushing into respective arrays
      if (families) aggFamilies.push(...families);
      if (types) aggTypes.push(...types);
      if (brands) aggBrand.push(...brands);
      if (sizes) aggSizes.push(...sizes);
      if (others) aggOthers.push(...others);
      if (prices) aggPrices.push(...prices);
    });

    return getFilterData(
      aggFamilies,
      aggTypes,
      aggBrand,
      aggSizes,
      aggOthers,
      getPriceRanges(aggPrices)
    );
  } catch (error) {
    console.log("useFilters.ts:130 | error", error);
    return getFilterData();
  }
}
export async function getFiltersBrands(
  brand: string,
  query?: boolean
): Promise<Filters> {
  let productTags = db.collection("brands").doc(brand);
  const aggFamily: string[] = [];
  const aggType: string[] = [];
  const aggSize: string[] = [];
  const aggPais: string[] = [];

  if (!query) return getFilterData();

  try {
    // Retrieve product tags and iterate over each document
    const querySnapshot = await productTags.get();

    const data = querySnapshot.data() as BrandData;

    // Destructure the data object to get the filter categories
    const { family, type, size, pais } = data;
    // Aggregate the data for each category by pushing into respective arrays
    if (family) aggFamily.push(...family);
    if (type) aggType.push(...type);
    if (size) aggSize.push(...size);
    if (pais) aggPais.push(...pais);

    return getFilterData(aggFamily, aggType, aggSize, aggPais);
  } catch (error) {
    console.log("useFilters.ts:130 | error", error);
    return getFilterData();
  }
}

export function useFiltersBrands(brand: string, query: boolean = true) {
  return useAsync(() => getFiltersBrands(brand, query), [brand, query]);
}
export default function useFilters(urlTypes: string[], query: boolean = true) {
  return useAsync(() => getFilters(urlTypes, query), [...urlTypes, query]);
}

function getPriceRanges(prices: number[]): string[] {
  const sortedPrices = prices.sort((a, b) => a - b);
  const len = prices.length;
  const steps = [0.2, 0.35, 0.5, 0.65, 0.8];
  return steps.map((mult, i) => {
    const num = roundToNearest(sortedPrices[Math.floor(len * mult)], 50);
    const num2 =
      roundToNearest(sortedPrices[Math.floor(len * steps[i + 1])], 50) - 1;
    if (i === 0) return `Menor a $${num}`;
    if (i === 4) return `Mayor a $${num}`;
    return `$${num} - $${num2}`;
  });
}

function roundToNearest(num: number, step: number) {
  return Math.round(num / step) * step;
}

export function getPriceRangesFromPriceString(
  prices: string[]
): [number?, number?][] {
  const ranges: [number?, number?][] = [];
  prices.forEach((price) => {
    const [num1, num2] = findNumbersInString(price);
    if (price.includes("Menor")) {
      ranges.push([undefined, num1]);
    } else if (price.includes("Mayor")) {
      ranges.push([num1, undefined]);
    } else {
      ranges.push([num1, num2]);
    }
  });
  return ranges;
}

function findNumbersInString(str: string): number[] {
  return (str.match(/\d+/g) || []).map((x) => Number(x));
}
