import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import QuantityInput from "./qty-input";
import { Link } from "react-router-dom";
import { Product } from "../models/product";
import { formatNumber, getProductUrl } from "../middleware/common-functions";

type CartItemProps = {
  product: Product;
  qty: number;
  handlePlus: () => void;
  handleMinus: () => void;
  handleRemoveProduct: () => void;
};

const CartItem: React.FC<CartItemProps> = ({
  product,
  qty,
  handlePlus,
  handleMinus,
  handleRemoveProduct,
}) => {
  const total = product.price_num ? formatNumber(product.price_num * qty) : 0;
  return (
    <Row className="product align-items-center mt-4">
      <Col className="col-6 col-md-1">
        <img
          src={
            product.images.length > 0 && product.images[0]
              ? product.images[0]
              : "https://via.placeholder.com/200"
          }
          alt={product.descp1}
          className="img-fluid"
        />
      </Col>
      <Col>
        <Link to={getProductUrl(product)}>
          {" "}
          <h5>{product.descp1}</h5>
        </Link>
        <div className="clave">{product.idpart}</div>
      </Col>
      <Col className="col-6" md={3}>
        <div className="cart-price md-bold text-center">
          <div>Precio Unitario:</div> ${product.price} <br />
          {product.contenido && product.price_num ? (
            <p>
              <span className="badge badge-info p-1 px-2">
                ${formatNumber(product.price_num / product.contenido)}/
                {product.idunmd}
              </span>
            </p>
          ) : null}
        </div>
      </Col>

      <Col className="col-6" md={2}>
        <QuantityInput
          qty={qty}
          handlePlus={handlePlus}
          handleMinus={handleMinus}
          className="mt-0"
          iconPlus=" icon-plus-square-o "
          iconMinus="icon-minus-square-o "
        />
      </Col>
      <Col className="col-6" md={2}>
        <div className="total md-bold text-center">
          <div>Precio Total:</div> ${total}
        </div>
      </Col>
      <Col className="col-6" md={2}>
        <Button onClick={handleRemoveProduct} variant="outline-danger">
          <i className="fas fa-trash"></i>
        </Button>
      </Col>
    </Row>
  );
};

export default CartItem;
