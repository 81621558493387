import { useAsync } from "react-async-hook";
import firebase from "firebase/app";
import { formatNumber } from "../middleware/common-functions";
import { db } from "../middleware/firebase";
import { UserType } from "../models/user_model";
import { Product } from "../models/product";
import { getPrice } from "../middleware/iva";

export type Filters = {
  types?: string[];
  grpsbi?: string[];
  priceRanges?: [number?, number?][];
  lines?: string[];
  brands?: string[];
  ids?: string[];
  limit?: number;
  pagination?: number;
  urlTypes?: string[];
  size?: string;
  promotions?: boolean;
  additionalAttributes?: string;
  xiazId?: number;
};

export async function getProducts(filters: Filters = {}, skip?: boolean) {
  let has_data: boolean = false;
  if (skip) return [];
  try {
    const {
      // types = [],
      grpsbi = [],
      // lines = [],
      brands = [],
      priceRanges = [],
      ids = [],
      limit = 80,
      pagination = 1,
      urlTypes = [],
      size = "",
      additionalAttributes = "",
      promotions = false,
    } = filters;
    let products = db.collection(
      "products"
    ) as any as firebase.firestore.Query<Product>;

    // priceRanges.forEach(([min, max]) => {
    //   if (min) {
    //     products = products.where(`zonePriceObjByXiazId.${xiazId}.price`, ">=", min)
    //   }
    //   if (max) {
    //     products = products.where(`zonePriceObjByXiazId.${xiazId}.price`, "<=", max);
    //   }
    // })

    if (urlTypes.length) {
      urlTypes.forEach((elem, index) => {
        if (elem) {
          products = products.where(`grpsbiObj.${index}`, "==", elem);
        }
      });
    }
    if (size) products = products.where("size", "==", size);

    if (promotions) {
      has_data = true;
      products = products
        .where("has_promotion", "==", true)
        .orderBy("grpsbiStr_lvl2");
    }

    if (additionalAttributes)
      products = products.where("grpsbiObj.4", "==", additionalAttributes);

    if (grpsbi.length && !(ids && ids.length)) {
      has_data = true;
      products = products.where(
        "grpsbi",
        "array-contains-any",
        filterGrpsbi(grpsbi)
      );
    } else if (grpsbi.length) {
      products = products.where("grpsbi", "array-contains", grpsbi[0]);
    }

    if (ids && ids.length) {
      has_data = true;
      products = products.where("id", "in", ids);
    }

    if (brands.length) {
      has_data = true;
      console.log("useProducts.ts:93 | brands", brands[0]);
      products = products.where("brand", "==", brands[0]);
    }
    if (has_data === false) return false;
    if (pagination > 1) {
      const first: any = products.limit(limit * pagination);
      const snapshot = await first.get();
      // Get the last document
      const last = snapshot.docs[snapshot.docs.length - 1];
      products = products.startAfter(last.data().grpsbiStr_lvl2);
    }
    const user: UserType = JSON.parse(localStorage.getItem("user") || "{}");
    const docs = (await products.limit(limit).get()).docs;

    const return_products = docs
      .map((doc) => {
        const product: Product = doc.data();
        if (!user || !product.prices) {
          product.price = "NOPRICE";
          return product;
        }
        const { price } = getPrice(product.prices, product.fconvs);
        product.price_num = price === "NOPRICE" ? 0 : price;
        product.price = formatNumber(price);

        return product;
      })
      .sort((a, b) => (a.descp1 > b.descp1 ? 1 : -1));
    /// these filters cannot be added to the query above
    /// consult (the docs)[https://firebase.google.com/docs/firestore/query-data/queries]
    const response_products = return_products
      .filter(
        (product) =>
          !priceRanges.length ||
          priceRanges
            .map(([min, max]) => {
              if (product.price === "NOPRICE") return false;
              const price = Number(product.price_num);
              if (!price) return false;
              if (min && max) {
                if (price > min && price < max) {
                  return true;
                } else {
                  return false;
                }
              } else if (min || max) {
                if ((min && price > min) || (max && price < max)) {
                  return true;
                } else {
                  return false;
                }
              } else {
                return false;
              }
            })
            .filter(Boolean).length
      )
      .filter((product) => product.price !== "NOPRICE" || !priceRanges.length)
      .filter(
        (product) =>
          !grpsbi.length ||
          grpsbi.some((elem) => product.grpsbi?.includes(elem))
      )
      .filter(
        (product) =>
          !(brands.length > 1) ||
          !product.aliasd ||
          brands.includes(product.aliasd)
      );
    return response_products;
  } catch (error) {
    console.error("Error", error);
    return [];
  }
}

export default function useProducts(filters: Filters = {}, skip?: boolean) {
  const location = JSON.parse(localStorage.getItem("location") || "{}");
  if (!location.id || location.id === "0" || location.id === 0) {
    location.id = 1;
  }
  return useAsync(
    () => getProducts({ ...filters, xiazId: location.id }, skip),
    [Object.values(filters).map(String).filter(Boolean).sort().join("") || ""]
  );
}
function filterGrpsbi(grpsbi: string[]) {
  ///remove empty strings
  let filtered = grpsbi.filter((elem) => elem !== "");
  ///remove string = "0"
  filtered = filtered.filter((elem) => elem !== "0");
  //return last element of array
  return filtered.slice(-1);
}
