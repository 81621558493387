import { useCallback, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import axiosInstance from "../middleware/axios";
import { fauth } from "../middleware/firebase";
import useAxiosRequest from "./useAxiosRequest";
import useLocalStorage from "./useLocalStorage";

// Regex for email and password validation
const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const passwordRegex = /(?=.*[0-9a-zA-Z]).{6,}/;

export default function useUserAuth() {
  // State declarations
  const [email, setEmail] = useState("");
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [emailErrMsg, setEmailErrMsg] = useState("");
  const [password, setPassword] = useState("");
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const [passwordErrMsg, setPasswordErrMsg] = useState("");
  const [fname, setFname] = useState("");
  const [fnameInvalid, setFnameInvalid] = useState(false);
  const [fnameErrMsg, setFnameErrMsg] = useState("");
  const [lname, setLname] = useState("");
  const [lnameInvalid, setLnameInvalid] = useState(false);
  const [lnameErrMsg, setLnameErrMsg] = useState("");
  const [alertMsg, setAlertMsg] = useState("");
  const [user, setUser] = useLocalStorage("user", false);

  // User authentication and data handling
  const [fireUser, fireLoading] = useAuthState(fauth);
  const {
    data: getUserData,
    execute: getUser,
    loading: getUserLoading,
  } = useAxiosRequest(
    () =>
      axiosInstance.post("scriptsUsersGetUser", {
        type: "email",
        string: email.toLowerCase().trim(),
      }),
    {
      catchHook: useCallback(() => {
        setAlertMsg("Ocurrió un error encontrando este usuario.");
      }, []),
      errorHook: useCallback(() => {
        setAlertMsg("Ocurrió un error encontrando este usuario.");
      }, []),
      showNotifications: false,
    }
  );
  const {
    data: newUserData,
    execute: createNewUser,
    loading: newUserLoading,
  } = useAxiosRequest(
    () =>
      axiosInstance.post("scriptsUsersNewUser", {
        user: {
          lname,
          fname,
          password,
          email: email.toLocaleLowerCase().trim(),
        },
      }),
    {
      catchHook: useCallback(() => {
        setAlertMsg(
          "Es necesario tener cuenta de cliente con Mi Cervesa para poder crear su cuenta en la página."
        );
      }, []),
      errorHook: useCallback(() => {
        setAlertMsg(
          "Es necesario tener cuenta de cliente con Mi Cervesa para poder crear su cuenta en la página."
        );
      }, []),
      showNotifications: false,
    }
  );

  // Effects
  useEffect(() => {
    if (fireUser && fireUser.email) {
      setEmail(fireUser.email);
      getUser();
    }
  }, [fireUser, getUser]);

  useEffect(() => {
    if (!user && newUserData && newUserData.user) {
      setUser(newUserData.user);
    }
  }, [newUserData, setUser, user]);

  useEffect(() => {
    if (!user && getUserData) {
      setUser(getUserData);
      window.location.href = "/";
    }
  }, [getUserData, setUser, user]);

  useEffect(() => {
    if (emailInvalid) {
      setEmailInvalid(!emailRegex.test(email.toLowerCase().trim()));
    }
  }, [email, emailInvalid]);

  useEffect(() => {
    if (passwordInvalid) {
      setPasswordInvalid(!passwordRegex.test(password));
    }
  }, [password, passwordInvalid]);

  useEffect(() => {
    if (fnameInvalid && fname) setFnameInvalid(false);
  }, [fname, fnameInvalid]);

  useEffect(() => {
    if (lnameInvalid && lname) setLnameInvalid(false);
  }, [lname, lnameInvalid]);

  // Input validation and error handling
  const handleInputErrors = useCallback(
    ({ includeNames = false } = {}) => {
      validateEmailAndPassword();
      if (includeNames) validateNames();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [email, fname, lname, password]
  );

  const validateEmailAndPassword = () => {
    validateEmail();
    validatePassword();
  };

  const validateEmail = () => {
    if (!email) {
      setEmailErrMsg("Favor de introducir un correo");
      setEmailInvalid(true);
    } else if (!emailRegex.test(email.toLowerCase())) {
      setEmailErrMsg("Favor de introducir un correo válido");
      setEmailInvalid(true);
    }
  };

  const validatePassword = () => {
    if (!password) {
      setPasswordErrMsg("Favor de introducir una contraseña");
      setPasswordInvalid(true);
    } else if (!passwordRegex.test(password)) {
      setPasswordErrMsg("La contraseña debe tener 6 caracteres");
      setPasswordInvalid(true);
    }
  };

  const validateNames = () => {
    if (!fname) {
      setFnameErrMsg("Favor de introducir un nombre");
      setFnameInvalid(true);
    }
    if (!lname) {
      setLnameErrMsg("Favor de introducir un apellido");
      setLnameInvalid(true);
    }
  };

  // User login and sign-up
  const login = useCallback(async () => {
    if (!emailInvalid && !passwordInvalid) {
      try {
        await fauth.signInWithEmailAndPassword(
          email.toLowerCase().trim(),
          password
        );
        getUser();
      } catch (error: any) {
        // Error handling
        const errorMessage = error.message;
        setAlertMsg("Ocurrió un error: " + errorMessage);
        console.log(error);
      }
    } else {
      handleInputErrors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, password, getUser, handleInputErrors]);

  const signUp = useCallback(() => {
    if (!emailInvalid && !passwordInvalid && !fnameInvalid && !lnameInvalid) {
      createNewUser();
    } else {
      handleInputErrors({ includeNames: true });
    }
  }, [
    createNewUser,
    emailInvalid,
    passwordInvalid,
    fnameInvalid,
    lnameInvalid,
    handleInputErrors,
  ]);

  const clearAlertMsg = useCallback(() => {
    setAlertMsg("");
  }, []);

  // Return statement for hook
  return {
    email,
    setEmail,
    emailInvalid,
    emailErrMsg,
    password,
    setPassword,
    passwordInvalid,
    passwordErrMsg,
    fname,
    setFname,
    fnameInvalid,
    fnameErrMsg,
    lname,
    setLname,
    lnameInvalid,
    lnameErrMsg,
    login,
    signUp,
    alertMsg,
    clearAlertMsg,
    user,
    loading: getUserLoading || newUserLoading || fireLoading,
  };
}
