import React from "react";
import Product from "../../components/product";
import { Container, Row } from "react-bootstrap";
import { UserType } from "../../models/user_model";
import { Product as ProductType } from "../../models/product";

type ProductsProps = {
  loading: boolean;
  products: ProductType[] | undefined | false;
};
const Products: React.FC<ProductsProps> = ({ loading, products }) => {
  const user: UserType = JSON.parse(localStorage.getItem("user") || "{}");

  return (
    <div className="products">
      <Container>
        <Row className="">
          {loading
            ? "Loading..."
            : (products || []).map((item, ind) => (
                <Product
                  key={`product_${ind}-${item.id}`}
                  product={item}
                  user={user}
                />
              ))}
        </Row>
      </Container>
    </div>
  );
};

export default Products;
