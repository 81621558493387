// Firebase
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAhkUPbnlMlY9uuLoDIq_sWVBImhpTzhW8",
  authDomain: "mi-cervesa.firebaseapp.com",
  projectId: "mi-cervesa",
  storageBucket: "mi-cervesa.appspot.com",
  messagingSenderId: "551851696578",
  appId: "1:551851696578:web:90fbc56630b5e94a55f65b",
  measurementId: "G-3WWBGPR3D8",
};

firebase.initializeApp(firebaseConfig);

export const fauth = firebase.auth();

const db = firebase.firestore();
if (window.location.hostname === "localhost") {
  // db.useEmulator("127.0.0.1", 8080);
}

export { db };

export const FieldValue = firebase.firestore.FieldValue;
