import { ProductPrice } from "../models/product";

import { formatNumber } from "./common-functions";
const special_iva = [3];

export function getIva() {
  const location = JSON.parse(localStorage.getItem("location") || "{}");
  if (location.id === "0" || location.id === 0) {
    location.id = 1;
  }
  let iva = 0.16;
  if (special_iva.find((i) => i === Number(location.id))) {
    iva = 0.08;
  }
  return iva;
}

type getPriceResponse = {
  price: number | "NOPRICE";
  regular_price?: string;
};

export function getPrice(
  prices: ProductPrice[],
  fconvs?: number,
  set_iva: boolean = false
): getPriceResponse {
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const user_priceListId = user.priceListId;
  const location = JSON.parse(localStorage.getItem("location") || "{}");
  if (location.id === "0" || location.id === 0) {
    location.id = 1;
  }
  const price_list = prices.find((p) => p.priceListId === user_priceListId);
  const regular_price_list = prices.find((p) => p.priceListId === "14");
  if (!price_list) {
    return { price: "NOPRICE" };
  }
  ///Ids of the locations with special IVA
  let iva = 0.16;
  let price = price_list.price;
  let regular_price: number | undefined = regular_price_list?.price
    ? Math.round((regular_price_list?.price + Number.EPSILON) * 100) / 100
    : undefined;
  if (regular_price && regular_price <= price) {
    regular_price = undefined;
  }
  if (special_iva.find((i) => i === Number(location.id))) {
    iva = 0.08;
  }
  if (fconvs) {
    if (regular_price) {
      regular_price = regular_price * fconvs;
    }
    price = price * fconvs;
  }
  if (set_iva) {
    price = price * iva + price;
  }
  price = Math.round((price + Number.EPSILON) * 100) / 100;
  return {
    price: parseFloat(price.toFixed(2)),
    regular_price: regular_price ? formatNumber(regular_price) : undefined,
  };
}
export function getRegularPrice(prices: any[], prev_price: number) {
  const location = JSON.parse(localStorage.getItem("location") || "{}");
  if (location.id === "0" || location.id === 0) {
    location.id = 1;
  }
  let price: false | number = false;
  const zone = prices.find((item: { xiaz_id: any }) => {
    // eslint-disable-next-line
    return item.xiaz_id == location.id;
  });
  if (!zone || !zone.ofrant) {
    return price;
  }
  price = parseFloat(zone.ofrant);
  ///Ids of the locations with special IVA
  const special_iva = [3];
  let iva = 0.16;
  if (special_iva.find((i) => i === Number(location.id))) {
    iva = 0.08;
  }
  price = price * iva + price;
  price = Math.round((price + Number.EPSILON) * 100) / 100;
  if (prev_price >= price) {
    return false;
  }
  return formatNumber(parseFloat(price.toFixed(2)));
}

export function renderIvaTxt(iva?: boolean) {
  if (!iva) {
    return "(excento IVA)";
  }
  const location = JSON.parse(localStorage.getItem("location") || "{}");
  let iva_value = 0.16;
  if (special_iva.find((i) => i === Number(location.id))) {
    iva_value = 0.08;
  }
  return `(${iva_value * 100}% IVA incluido)`;
}
