import React from "react";
import { Col } from "react-bootstrap";

type ShippingItemProps = {
  icon: string;
  label: string;
  name: string;
  description: string;
  id: string;
  active: boolean;
  clickFun?: any;
};

const ShippingItem: React.FC<ShippingItemProps> = ({
  icon,
  label,
  name,
  description,
  id,
  active,
  clickFun,
}) => {
  return (
    <Col sm={12} className="mb-2">
      <label>
        <input
          name={name}
          type="checkbox"
          value={id}
          id={id}
          checked={active}
          onChange={clickFun}
        />
        <div className="shipping-item">
          <i className={`${icon}`}></i>
          <div className="shipping-item-content">
            <div> {label}</div>
            <div>{description}</div>
          </div>
        </div>
      </label>
    </Col>
  );
};

export default ShippingItem;
