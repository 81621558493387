import React from "react";
import { AddressType } from "../../../models/address";
import { BranchItem } from "../../../models/branch_model";

type ShippingInfoProps = {
  deliveryData?: AddressType;
  selectedBranch?: BranchItem;
};
const ShippingInfo: React.FC<ShippingInfoProps> = ({
  deliveryData,
  selectedBranch,
}) => (
  <div className="shipping-info pt-3 pb-3">
    <h4>Información de Envío</h4>
    {deliveryData ? (
      <>
        <div className="row">
          <div className="col-md-9">
            <b>Enviar a:</b>
            <p>
              {deliveryData?.name} {deliveryData?.lastname}
            </p>
          </div>
          <div className="col-md-3">
            <b>Teléfono:</b>
            <p>{deliveryData?.phone}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-9">
            <b>Dirección:</b>
            <p>
              {deliveryData?.address} {deliveryData?.city},{" "}
              {deliveryData?.state}
            </p>
          </div>
          <div className="col-md-3">
            <b>CP:</b>
            <p>{deliveryData?.zip}</p>
          </div>
        </div>
      </>
    ) : selectedBranch ? (
      <div className="row">
        <div className="col-md-12 text-capitalize">
          <b>Recoger en tienda:</b>
          <p>{`${selectedBranch.city} | ${selectedBranch.descrp}`}</p>
        </div>
      </div>
    ) : null}
  </div>
);

export default ShippingInfo;
