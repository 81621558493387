import React from "react";
import Table from "./table";
import { Container, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useCollectionData } from "react-firebase-hooks/firestore";

import { db, FieldValue } from "../../middleware/firebase";
import Button from "../../components/buttons";
import useProducts from "../../hooks/useProducts";
import { makeid } from "../../middleware/common-functions";
import { SaveOrderModal } from "../../components/Modals/save-order";
import { useState } from "react";
import { getTotals } from "../../middleware/total";
import { UserType } from "../../models/user_model";

type CartItemsProps = {
  user: UserType;
};
const CartItems: React.FC<CartItemsProps> = ({ user }) => {
  const history = useHistory();
  const [saveOrderModalSow, setSaveOrderModalSow] = useState(false);
  const [loadingSaveOrderModal, setLoadingSaveOrderModal] = useState(false);
  const [cartProductDocs] = useCollectionData(
    db
      .collection("cart")
      .doc(user ? user.uid : "UXIONO")
      .collection("products")
  );
  const { result: products } = useProducts(
    {
      ids: (cartProductDocs || []).map(({ id }) => id) as any,
    },
    !Boolean(cartProductDocs && cartProductDocs.length)
  );
  const productTotals = getTotals(
    (products || []).map((p) => ({
      ...p,
      qty:
        ((cartProductDocs || []).filter((x) => x.id === p.id)[0] || {}).qty ||
        0,
    })) as any
  );
  console.log("cartItems.tsx:43 | productTotals", productTotals);
  const handleQtyChange = async (
    operation: "PLUS" | "MINUS",
    productId: string,
    currQty: number
  ) => {
    if (operation === "MINUS" && currQty === 1) {
      return;
    }
    await db
      .collection("cart")
      .doc(user ? user.uid : "UXIONO")
      .collection("products")
      .doc(productId)
      .update({ qty: FieldValue.increment(operation === "PLUS" ? 1 : -1) });
  };

  const handleRemoveProduct = async (productId: string) => {
    await db
      .collection("cart")
      .doc(user ? user.uid : "UXIONO")
      .collection("products")
      .doc(productId)
      .delete();
  };

  const handleSaveCart = async (orderName: string) => {
    setLoadingSaveOrderModal(true);
    const id = makeid(12);
    await db
      .collection("quotes")
      .doc(user ? user.uid : "UXIONO")
      .collection("quotes")
      .doc(id)
      .set({
        total: productTotals,
        name: orderName,
        products,
        productIds: cartProductDocs,
        id,
        timestamp: FieldValue.serverTimestamp(),
      });
    const deletePromises = (cartProductDocs || [])
      .map(({ id }) => id)
      .map(
        async (pId) =>
          await db
            .collection("cart")
            .doc(user ? user.uid : "UXIONO")
            .collection("products")
            .doc(pId)
            .delete()
      );
    await Promise.all(deletePromises);
    setLoadingSaveOrderModal(false);
    setSaveOrderModalSow(false);
    history.push("/cuenta");
  };

  const quantities = (cartProductDocs || []).reduce((obj, { qty, id }) => {
    const newObj = { ...obj };
    newObj[id] = qty;
    return newObj;
  }, {} as any);
  const handleCheckout = () => {
    history.push("/checkout?showToast=true");
  };

  return (
    <>
      <SaveOrderModal
        loading={loadingSaveOrderModal}
        saveOrder={handleSaveCart}
        show={saveOrderModalSow}
        setShow={setSaveOrderModalSow}
      />
      <Container>
        <Row className="mb-4 ">
          <Col>
            <h2>Orden de Compra</h2>
          </Col>
        </Row>
        <Table
          handleRemoveProduct={handleRemoveProduct}
          quantities={quantities}
          products={products || []}
          handleQtyChange={handleQtyChange}
        />
        <Row>
          <Col md={7}></Col>
          <Col md={5}>
            <div className="total-chart py-3 px-4 mt-4 border-left">
              <Row>
                <Col>
                  <Row>
                    <Col>Sub Total</Col>
                    <Col>${productTotals.subtotal}</Col>
                  </Row>
                  <Row>
                    <Col>IVA</Col>
                    <Col>${productTotals.tax}</Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="md">Total de Orden de Compra</div>
                    </Col>
                    <Col>
                      <div className="md">${productTotals.total}</div>
                    </Col>
                  </Row>
                </Col>
                <Col sm={12}>
                  <div className="mt-3 coupon-form ">
                    <input type="text" placeholder="Código Promocional"></input>
                    <button>Aplicar</button>
                  </div>
                  <div>
                    <Button
                      disabled={!(cartProductDocs || []).length}
                      onClick={handleCheckout}
                      children="Continuar con Orden de Compra"
                      buttonclasses="primary mt-2"
                      width="100%"
                      icon=""
                      title="Submit"
                    />
                  </div>
                  <div>
                    <Button
                      disabled={!(cartProductDocs || []).length}
                      onClick={() => setSaveOrderModalSow(true)}
                      children="Guardar mi Orden"
                      buttonclasses="primary-outline mt-2"
                      width="100%"
                      icon="icon-floppy-o"
                      title="Submit"
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CartItems;
