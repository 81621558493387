import React from "react";
import { ZoneItem } from "../../models/branch_model";
import Button, { IconButton } from "../../components/buttons";
import Loader from "../../components/utils/loader";

type BranchSelectionProps = {
  branches: ZoneItem[];
  next: any;
  selectBranch: any;
  active_branch: any;
};

const BranchSelection: React.FC<BranchSelectionProps> = ({
  branches,
  next,
  selectBranch,
  active_branch,
}) => {
  if (branches.length === 0) {
    return <Loader />;
  }
  return (
    <div className="branch_secion">
      <h5>Selecciona una sucursal</h5>
      <div className=" btn_rich_container">
        {branches &&
          branches.map((zone_branches) =>
            zone_branches.xiams.map((branch) => {
              if (!branch.address) {
                return null;
              }
              const address = branch.address.split(",").splice(0, 3).join(", ");
              return (
                <IconButton
                  key={`branch_${branch.id}`}
                  hover="primary"
                  classes="mb-2 text-capitalize"
                  active={active_branch && branch.id === active_branch}
                  title={`${branch.city} | ${branch.descrp}`}
                  icon="fas fa-map-marker-alt"
                  help={address}
                  onClick={selectBranch}
                  value={branch.id}
                />
              );
            })
          )}
      </div>
      <Button
        disabled={!active_branch}
        onClick={() => {
          next("payment");
        }}
        children="Continuar"
        width="100%"
        icon=""
        buttonclasses="dark mt-2"
      />
    </div>
  );
};

export default BranchSelection;
