import { PaymentTypes } from "../models/checkout";
import { Product } from "../models/product";
import { ProductItem } from "../models/product_model";

export function capitalize(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function compare(a: { name: string }, b: { name: string }) {
  const value_A = a.name.toUpperCase();
  const value_B = b.name.toUpperCase();

  let comparison = 0;
  if (value_A > value_B) {
    comparison = 1;
  } else if (value_A < value_B) {
    comparison = -1;
  }
  return comparison;
}

export function generateRange(min: any, max: number, step: any) {
  let arr = [];
  for (let i = min; i <= max; i += step) {
    arr.push(i);
  }

  return arr;
}

export function slugify(string: { toString: () => string }) {
  ///found on https://gist.github.com/hagemann/382adfc57adbd5af078dc93feef01fe1
  const a =
    "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
  const b =
    "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
  const p = new RegExp(a.split("").join("|"), "g");

  return (
    string
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(p, (c: string) => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, "-and-") // Replace & with 'and'
      // eslint-disable-next-line
      .replace(/[^\w\-]+/g, "") // Remove all non-word characters
      // eslint-disable-next-line
      .replace(/\-\-+/g, "-") // Replace multiple - with single -
      // eslint-disable-next-line
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, "")
  ); // Trim - from end of text
}

export function formatNumber(num: string | number, round = true) {
  if (round) {
    if (typeof num === "string") num = parseFloat(num);
    num = num.toFixed(2);
  }
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export function toNumber(num: string) {
  return parseFloat(num.split(",").join());
}

export function shippingTxt(shipping: any) {
  switch (shipping) {
    case "shipping":
      return "Envío a Domicilio";
    default:
      return "Recoger en Sucursal";
  }
}

export function paymentTxt(payment_type: PaymentTypes) {
  switch (payment_type) {
    case "credit":
      return "Pago con Crédito en Mi Cervesa";
    case "online":
      return "Pago con Tarjeta Online";
    default:
      return "Pago por Transferencia";
  }
}

export function statusTxt(status: any) {
  switch (status) {
    case "shipped":
      return "Envíado";

    default:
      return "Pendiente";
  }
}

export function getNeededAmount(amunt_needed: number, product_amount: number) {
  if (!product_amount || !amunt_needed || amunt_needed === 0) {
    ///when it's zero always return one,
    //customer only need one per instalation
    return 1;
  }
  let needed_amount = (amunt_needed * product_amount).toFixed(2);
  const decimals = (needed_amount + "").split(".").pop();
  if (decimals && Number(decimals) >= 20) {
    ///equal or grater than .20
    needed_amount = Math.ceil(needed_amount as any) as any;
  } else {
    needed_amount = Math.floor(needed_amount as any) as any;
  }
  return needed_amount || 1;
}

export function getRandomNumber(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function updateCartCants() {
  const cart_items = JSON.parse(localStorage.getItem("cart") || "{}");
  const cart_elem = document.getElementById("cart_items_cant");
  const cart_elem_mob = document.getElementById("cart_items_cant_mob");
  if (!cart_elem) {
    return;
  }
  if (!cart_items) {
    cart_elem.textContent = 0 as any;
    cart_elem_mob!.textContent = 0 as any;
    return;
  }
  const cant = cart_items.length;
  cart_elem.textContent = cant;
  cart_elem_mob!.textContent = cant;
}

export function priceDiscount(regular: string, discounted: string) {
  return parseFloat(
    String(
      100 -
        (parseFloat(discounted) * 100) / parseFloat(regular.replace(",", ""))
    )
  ).toFixed(1);
}

export function aMeses(
  total: number,
  meses: { comission: number; meses: number }
) {
  const new_total = total + total * meses.comission;
  const monthly_pay = new_total / meses.meses;

  return {
    monthly_pay: formatNumber(monthly_pay),
    total_pay: formatNumber(new_total),
  };
}

export function makeid(length: number) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function getProductUrl(product: Product | ProductItem) {
  if (
    !product.grpsbi ||
    (product.grpsbi && product.grpsbi.length < 3) ||
    !product.descp1
  ) {
    return `/producto/${product.id}`;
  }
  return `/producto/${slugify(product.grpsbi[0])}/${slugify(
    product.grpsbi[1]
  )}/${slugify(product.grpsbi[2])}/${slugify(product.descp1)}/${product.id}`;
}
