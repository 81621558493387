import { ApolloProvider } from "@apollo/client";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./css/font-awesome.css";
import "./css/app.css";

import { client } from "./middleware/graphql";
import Layout from "./layouts";

///pages
import Home from "./pages/home";
import ProductPage from "./pages/product";
import CartPage from "./pages/cart";
import Category from "./pages/category";
import CheckoutPage from "./pages/checkout";
import ScrollTop from "./components/utils/ScrollTop";
import WhoWeArePage from "./pages/who-we-are";
import ContactUs from "./pages/client/contact";
import ClientPage from "./pages/client";
import LoginPage from "./pages/login";
import SignUpPage from "./pages/login/sing-up";
import MiCuenta from "./pages/cuenta";
import ForgotPage from "./pages/login/forgot";
import { fauth } from "./middleware/firebase";
import StaticPages from "./pages/static_pages";
import BrandsPage from "./pages/brands";
import PaymentPage from "./pages/payment";

function App() {
  const [user] = useAuthState(fauth);
  return (
    <Router>
      <ScrollTop>
        <ApolloProvider client={client}>
          <Layout>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              pauseOnHover
            />
            <Switch>
              <Route path="/login" component={LoginPage}>
                {user ? <Redirect to="/cuenta" /> : null}
              </Route>
              <Route path="/cuenta/:section?/:item_id?" component={MiCuenta}>
                {!user ? <Redirect to="/login" /> : null}
              </Route>
              <Route path="/info/:post_name" component={StaticPages} />
              <Route path="/sing-up" component={SignUpPage} />
              <Route path="/recuperar" component={ForgotPage} />
              <Route path="/checkout" component={CheckoutPage} />
              <Route path="/pago/:order_id?" component={PaymentPage} />
              <Route path="/marca/:brand_name" component={BrandsPage} />
              <Route
                path="/productos/:primary?/:secondary?/:tertiary?"
                component={Category}
              />
              <Route path="/carrito" component={CartPage} />
              <Route path="/como-ser-cliente" component={ClientPage} />
              <Route path="/quienes-somos" component={WhoWeArePage} />
              <Route path="/contacto" component={ContactUs} />
              <Route
                path="/producto/:primary/:secondary/:tertiary/:product_title/:id"
                component={ProductPage}
              />
              <Route path="/producto/:id" component={ProductPage} />
              <Route path="/" component={Home} />
            </Switch>
          </Layout>
        </ApolloProvider>
      </ScrollTop>
    </Router>
  );
}

export default App;
