import React from "react";
import { Carousel, Container, Row, Col } from "react-bootstrap";
const hero = {
  minHeight: "350px",
  backgroundImage: "url(/images/home/Bitmap.jpg)",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
};
const heroContent = {
  maxWidth: "500px",
  margin: "auto",
};

const Hero = () => (
  <Container>
    <Carousel>
      <Row className="text-center text-white" style={hero}>
        <Col className="pt-4">
          <div style={heroContent}>
            <h1>MICERVESA</h1>
            <p>
              Somos distribuidores en mayoreo de las mejores marcas de insumos
              para micro cervecerías y tiendas de homebrewing en México.
            </p>
          </div>
        </Col>
      </Row>
    </Carousel>
  </Container>
);

export default Hero;
