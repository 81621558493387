import React, { useState, useEffect, useCallback } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const Header: React.FC = () => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  const handleScroll = useCallback(() => {
    // find current scroll position
    const currentScrollPos = window.pageYOffset;

    // set state based on location info (explained in more detail below)
    setVisible(
      (prevScrollPos > currentScrollPos &&
        prevScrollPos - currentScrollPos > 0) ||
        currentScrollPos < 200
    );

    // set state to new scroll position
    setPrevScrollPos(currentScrollPos);
  }, [prevScrollPos]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos, visible, handleScroll]);

  return (
    <section
      className="header-checkout"
      style={{ top: visible ? "0" : "-180px" }}
    >
      <Container>
        <div className="logo-checkout pb-2">
          <Link to="/">
            <img src="/images/nav/logo-mi-cerveza.png" alt="Mi Cerveza" />
          </Link>
        </div>
        <Row>
          {" "}
          <Col lg={5} className="col-sm-8 col-md-12" >
            <div className="xxs-bold d-none d-lg-block text-center">
              Horario: Lunes - Viernes 9:00am - 6:00pm (CUU)
            </div>
          </Col>
          <Col lg={2} className="col-sm-12 col-md-4">
            <div className="xxs-bold text-lg-left">
              {" "}
              <i className="icon-phone white pr-1"></i> +52 (614) 427-86 22
            </div>
          </Col>
          <Col lg={2} className="col-sm-12 col-md-4">
            {" "}
            <div className="xxs-bold text-lg-left">
              {" "}
              <i className="icon-phone white pr-1"></i>+52 (614) 413-02 16
            </div>
          </Col>
          <Col lg={3} className="col-sm-12 col-md-4">
            {" "}
            <div className="xxs-bold text-lg-left">
              {" "}
              <i className="icon-envelope-o white pr-2"></i>ventas@micervesa.mx
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Header;
