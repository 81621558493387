import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

export default function useAxiosRequest<T = any>(
  axiosRequest: () => Promise<AxiosResponse>,
  config: {
    paramsAreSet?: boolean;
    showNotifications?: boolean;
    initialQuery?: boolean;
    thenHook?: () => void;
    finallyHook?: () => void;
    catchHook?: () => void;
    errorHook?: () => void;
    genericError?: string;
  } = {}
) {
  const {
    paramsAreSet = true,
    showNotifications = true,
    initialQuery = false,
    thenHook = () => void 0,
    finallyHook = () => void 0,
    catchHook = () => void 0,
    errorHook = () => void 0,
    genericError = "Ocurrió un error",
  } = config;
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<null | T>(null);
  const [error, setError] = useState<null | string>(null);
  const [shouldExecute, setShouldExecute] = useState<boolean>(
    initialQuery ? true : false
  );
  useEffect(() => {
    if (shouldExecute) {
      if (paramsAreSet) {
        setData(null);
        setLoading(true);
        axiosRequest()
          .then(({ data }) => {
            setLoading(false);
            if (data.error) {
              setError(data.error);
              if (showNotifications) {
                toast("Error: " + data.error);
              }
              errorHook();
            } else {
              setData(data);
              thenHook();
            }
          })
          .catch((error) => {
            setLoading(false);
            console.log("Error useAxiosRequest.ts:43 | ", error);
            setError(genericError);
            if (showNotifications) {
              toast("Error: " + genericError);
            }
            catchHook();
          })
          .finally(() => {
            setShouldExecute(false);
            finallyHook();
          });
      } else {
        setShouldExecute(false);
        if (showNotifications) {
          toast("Error: Asegurate de llenar los campos de búsqueda.");
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldExecute]);
  return {
    loading,
    data,
    error,
    shouldExecute,
    setShouldExecute,
    execute: () => setShouldExecute(true),
  };
}
