import React from "react";
import { Container, Col, Row } from "react-bootstrap";

const Banner = () => {
  return (
    <section className="header-banner py-2">
      <Container>
        <Row>
          {" "}
          <Col lg={5}>
            <div className="xxs-bold ">
              Horario: Lunes - Viernes 9:00am - 6:00pm (CUU)
            </div>
          </Col>
          <Col>
            <div className="xxs-bold ">
              {" "}
              <a href="tel:614 427-86 22" className="white">  <i className="icon-phone white"></i> +52 (614) 427-86 22</a>
            </div>
          </Col>
          <Col>
            {" "}
            <div className="xxs-bold ">
              {" "}
            <a href="tel:614 413-02 16">  <i className="icon-phone white"></i>+52 (614) 413-02 16</a>
            </div>
          </Col>
          <Col>
            {" "}
            <div className="xxs-bold">
              {" "}
             <a href="mailto:ventas@micervesa.mx"> <i className="icon-envelope-o white"></i>ventas@micervesa.mx</a>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Banner;
