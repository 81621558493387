import React from "react";

type InputProps = {
  id: string;
  label: string;
  type?: string;
  value?: string;
  name?: string;
  help?: string;
  placeholder?: string;
  rows?: number;
  className?: string;
  error?: string;
  title?: string;
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
};
const Input: React.FC<InputProps> = ({
  label,
  type = "text",
  name,
  help,
  id,
  value,
  rows,
  className,
  placeholder,
  onChange,
  error,
  title,
}) => {
  return (
    <div className={`form-group ${className || ""}`}>
      {" "}
      <label className="mb-0">{label}</label>
      {!rows && (
        <input
          className="form-control mt-1"
          type={type}
          name={name}
          id={id}
          value={value || ""}
          title={title}
          onChange={onChange}
          placeholder={placeholder}
        />
      )}
      {rows && (
        <textarea
          className="form-control mt-1"
          id={id}
          rows={rows}
          value={value || ""}
          onChange={onChange}
          placeholder={placeholder}
        />
      )}
      {help && <small className="text-secondary">{help}</small>}
      {error && <div className="error">{error}</div>}
    </div>
  );
};

type SelectProps = {
  id: string;
  label: string;
  className?: string;
  placeholder: string;
  value?: string;
  error?: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  options: Array<{ value: string; label: string }>;
};
export const Select = ({
  label,
  id,
  value,
  className,
  placeholder,
  onChange,
  error,
  options,
}: SelectProps) => {
  return (
    <div className={`form-group ${className || ""}`}>
      {" "}
      <label className="mb-0">{label}</label>
      <select
        className="form-control mt-1"
        id={id}
        value={value || ""}
        onChange={onChange}
      >
        <option value="false">{placeholder}</option>
        {options.map((item, key) => (
          <option key={key} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
      {error && <div className="error">{error}</div>}
    </div>
  );
};

export default Input;
