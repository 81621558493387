import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "../../middleware/moment";
import { PaymentTypes } from "../../models/checkout_model";

import { shippingTxt, formatNumber } from "../../middleware/common-functions";
import {
  OrderDbType,
  OrderPrductItem,
  OrderType,
} from "../../models/order_model";
import { getOrderApi } from "../../middleware/order-functions";

type OrderProps = {
  order_data: OrderDbType;
  backUrl: string;
  type: "orders" | "quotes" | "cart" | "checkout" | "order";
};
const Order: React.FC<OrderProps> = ({ order_data, backUrl, type }) => {
  const [loading, setLoading] = useState(true);
  const [order, setOrder_data] = useState<OrderType>(order_data);
  useEffect(() => {
    console.log("order.tsx:26 | order_data", order_data);
    getOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order_data]);

  const getOrder = async () => {
    if (order_data.api_id) {
      const order = await getOrderApi(order_data.api_id);
      if (order) {
        setOrder_data({ ...order, date: order_data.date });
      }
      setLoading(false);
    }
  };

  return (
    <div className="order_data">
      <div className="preheader">
        <Link className="btn pl-0 text-capitalize " to={backUrl}>
          <i className="fas fa-chevron-left"></i> Atrás
        </Link>
      </div>
      <div className="header">
        <h1>{order.api_id ? `Orden #${order.api_id}` : "Orden"}</h1>
        <h6 className="d-flex justify-content-between">
          {type !== "quotes" && (
            <span>
              Estatus de la Orden:{" "}
              <b className="text-info">
                {loading ? "Cargando..." : order.status || "En revisión"}{" "}
              </b>
            </span>
          )}
          <span>
            {order.ipgTransactionId && (
              <span>ID Pago: #{order.ipgTransactionId}</span>
            )}
          </span>
          <span>
            {moment(order.date.toDate()).format("DD MMMM, YYYY HH:MM a")}{" "}
          </span>
        </h6>
      </div>
      <hr />
      <div className="row ">
        <div className="col-md-4 order-md-2">
          <div className="order_info border p-4">
            {order.delivery_type && (
              <div className="my-3">
                <h5>
                  <b>Forma de Entrega</b>
                </h5>
                <p>
                  <b>{shippingTxt(order.delivery_type)}</b> <br />
                  {renderHelpText(order)}
                </p>
              </div>
            )}
            {order.payment_type && (
              <div className="my-3">
                <h5>
                  <b>Forma de Pago</b>
                </h5>
                <p>{renderPayment(order.payment_type)}</p>
              </div>
            )}
            <div className="my-3">
              {type === "orders" ? (
                <RenderTotals totals={order.totals} type={type} />
              ) : (
                <RenderTotals totals={order.totals} type={type} />
              )}
            </div>
          </div>
          <OrderCtas
            type={type}
            order_id={order.api_id}
            payment_type={order.payment_type}
          />
        </div>
        <div className="col-md-8 product_cart order-md-1">
          <Products products={order.products} type={type} />
        </div>
      </div>
    </div>
  );
};

const RenderTotals = ({ totals, type }: any) => {
  if (type === "quotes") {
    return (
      <div>
        <p>
          <span>Total</span> <strong>${formatNumber(totals.total)}</strong>
        </p>
      </div>
    );
  }
  return (
    <table>
      <tbody>
        <tr>
          <td>
            <b>Sub total:</b>
          </td>
          <td>${formatNumber(totals.subtotal)}</td>
        </tr>
        {totals.shipping ? (
          <tr>
            <td>
              <b>Envío:</b>
            </td>
            <td>${formatNumber(totals.shipping)}</td>
          </tr>
        ) : null}
        <tr>
          <td>
            <b>IVA:</b>
          </td>
          <td>${formatNumber(totals.tax)}</td>
        </tr>
        <tr>
          <td>
            <b>Total:</b>
          </td>
          <td className="text-right">
            <b className="text-success">${formatNumber(totals.total)}</b>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

type OrderCtasProps = {
  type: string;
  order_id?: string;
  payment_type: PaymentTypes;
};
const OrderCtas: React.FC<OrderCtasProps> = ({
  type,
  order_id,
  payment_type,
}) => (
  <div className="order_ctas mt-2">
    {type === "quotes" ? (
      <button
        className="btn btn-warning btn-block text-left"
        // onClick={sendToCart}
      >
        <i className="fas fa-cart-plus mr-2"></i>Enviar al Carrito
      </button>
    ) : (
      <>
        {" "}
        {payment_type === "online" ? (
          <Link
            className="btn btn-primary btn-block text-left"
            to={`/pago/${order_id}`}
          >
            <i className="far fa-credit-card mr-2 text-white py-3"></i> Realizar
            Pago Online
          </Link>
        ) : (
          <button className="btn btn-warning btn-block text-left">
            <i className="fas fa-file-invoice-dollar mr-2"></i> Confirmar Pedido
          </button>
        )}{" "}
        <button className="btn btn-link text-danger mt-4 btn-block text-left">
          <i className="fas fa-times text-danger mr-2"></i> Cancelar Orden
        </button>
      </>
    )}
  </div>
);

const Products = ({ products, type }: any) => (
  <div className="cart_resume">
    {products.map((product: any) => (
      <ProductItem key={product.id} product={product} />
    ))}
  </div>
);

function renderHelpText(data: OrderType) {
  if (data.address) {
    return `${data.address.address} ${data.address.city} ${data.address.state} ${data.address.zip} `;
  } else {
    return (
      <span className="text-capitalize">{data.selectedBranch?.address}</span>
    );
  }
}

function renderPayment(payment: any) {
  if (payment === "online") {
    return "Pagado Online";
  } else if (payment === "coppel") {
    return "Pagado por Coppel Pay";
  } else {
    return "Transferencia Electrónica";
  }
}

type ProductItemProps = {
  product: OrderPrductItem;
};
const ProductItem: React.FC<ProductItemProps> = ({ product }) => {
  const product_display_qty = product.fconvs
    ? product.qty / product.fconvs
    : product.qty;
  return (
    <div className="row product_item mb-3">
      <div className="col-3">
        <Link to={`/producto/${product.id}`}>
          <img
            src={product.image || "https://via.placeholder.com/150"}
            className="img-fluid"
            alt={product.idpart}
          />
        </Link>
      </div>
      <div className="col-6">
        <h6>
          <Link to={`/producto/${product.id}`}>{product.title}</Link>
          <br /> <small>{product.idpart}</small>
        </h6>
        <p>
          Precio: ${formatNumber(product.price)} <br />
          Cantidad: {product_display_qty} <br />
          Total: ${formatNumber(product.price_num * product_display_qty)}
        </p>
      </div>
    </div>
  );
};

export default Order;

// export default function Order() {
//   return <div>Order</div>;
// }
